import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, ButtonGroup, DropdownButton, Dropdown, Form, Spinner, Table, Modal, Badge, Tabs, Tab } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { GET, POST } from '../../api';
import Pagination from '../_partials/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheck, faEye, faEyeSlash, faFileExcel, faFileLines, faFolder, faGlobe, faImage, faList, faListCheck, faPencil, faPlus, faSearch, faTrash, faTrashCan, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import moment from 'moment/moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

var Chance = require('chance');
var chance = new Chance();
const portalURL = process.env.REACT_APP_PORTAL_URL;

class TOK extends Component {

  docRef = React.createRef();

  defaultFormFields = {
    ib_tok_id: 0,
    ib_tok_name: '',
    ib_tok_acs: null,
    ib_tok_topic_id: null,
    ib_tok_note: '',
    ib_tok_topic: '',
    ib_tok_research_question: '',
    ib_tok_supervisors: [],
    ib_tok_status: 'To Be Determined',
    ib_tok_approved: 0,
    ib_tok_completed: 0
  };

  defaultReflectionFields = {
    ib_tok_ref_id: 0,
    ib_tok_ref_title: '',
    ib_tok_ref_desc: '',
    ib_tok_id: 0,
    details: [],
    ib_tok_ref_date: new Date(),
  };

  defaultReflectionDetailFields = {
    ib_tok_refd_id: 0,
    ib_tok_refd_type: '',//URL', 'Image', 'File
    ib_tok_refd_value: '',
    ib_tok_refd_name: ''
  };

  defaultTodoFields = {
    ib_tok_todo_id: 0,
    ib_tok_todo_desc: '',
    ib_tok_id: 0,
    details: [],
    ib_tok_todo_date: new Date(),
  };

  defaultTodoDetailFields = {
    ib_tok_todod_id: 0,
    ib_tok_todod_type: '',//URL', 'Image', 'File
    ib_tok_todod_value: '',
    ib_tok_todod_name: ''
  };

  // SELECT ``, ``, ``, ``, `ib_tok_pres_approved`, ``, `ib_tok_pres_selected`, `center_id`, `created_on`, `created_by`, `updated_on`, `updated_by`, `seen`, `ib_tok_pres_app`, ``, ``, ``, ``, `` FROM `` WHERE 1
  // academic_ib_tok_presentations
  defaultPresFields = {
    ib_tok_pres_id: 0,
    ib_tok_pres_title: '',
    ib_tok_pres_know_quest: '',
    ib_tok_pres_concept: '',
    ib_tok_pres_evaluation: '',
    ib_tok_pres_application: '',
    ib_tok_pres_argument: '',
    ib_tok_pres_conclusion: '',
    ib_tok_id: 0,
    ib_tok_pres_date: new Date(),
    details: [],
  };

  defaultPresDetailFields = {
    ib_tok_presd_id: 0,
    ib_tok_presd_type: '',//URL', 'Image', 'File
    ib_tok_presd_value: '',
    ib_tok_presd_name: ''
  };

  state = {
    ibTOKData: { ...this.defaultFormFields },
    ib_tok_status_data: ['Excellent', 'On Track', 'Concern', 'To Be Determined'],
    getSupervisorsData: [],
    acsData: [],
    topicsData: [],
    deleteModal: { show: false, id: 0, action: () => { } },
    showFormModal: false,
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'academic_ib_tok.ib_tok_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'academic_ib_tok.ib_tok_id',
          column: 'ib_tok_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'academic_ib_tok.ib_tok_name',
          column: 'ib_tok_name',
          name: 'Subject',
          visibility: true,
          sortable: true,
        },
        {
          id: 'academic_ib_tok.ib_tok_status',
          column: 'ib_tok_status',
          name: 'Status',
          visibility: true,
          sortable: true,
        },
        {
          id: 'academic_ib_tok.ib_tok_approved',
          column: 'ib_tok_approved',
          name: 'Approved',
          visibility: true,
          sortable: true,
        },
        {
          id: 'academic_ib_tok.ib_tok_completed',
          column: 'ib_tok_completed',
          name: 'Completed',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },

    showDocumentModal: false,
    editDocumentModal: false,
    ib_tok_doc_id: 0,
    ib_tok_doc_caption: '',
    ib_tok_doc_name: '',
    ib_tok_doc_date: new Date(),
    TOKDocWorking: false,

    ib_tok_note_id: 0,
    ib_tok_note: '',
    showNoteModal: false,
    editNoteModal: false,
    TOKNoteWorking: false,

    showPlanningModal: false,
    editPlanningModal: false,
    ib_tok_plan_id: 0,
    ib_tok_plan_text: '',
    ib_tok_plan_date: new Date(),
    TOKPlanningWorking: false,

    // Reflection modal and form
    addReflectionModal: {
      show: false,
      working: false,
      isEditing: false,
      toggle: (addReflectionForm = { ...this.defaultReflectionFields }, isEditing = false) => {
        this.setState({
          addReflectionModal: { ...this.state.addReflectionModal, show: !this.state.addReflectionModal.show, working: false, isEditing },
          addReflectionForm
        })
      },
    },
    addReflectionForm: { ...this.defaultReflectionFields },

    showReflectionListModal: false,
    refcdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'tokref.ib_tok_ref_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'tokref.ib_tok_ref_title',
          column: 'ib_tok_ref_title',
          name: 'Journal Title',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },

    addTodoModal: {
      show: false,
      working: false,
      isEditing: false,
      toggle: (addTodoForm = { ...this.defaultTodoFields }, isEditing = false) => {
        this.setState({
          addTodoModal: { ...this.state.addTodoModal, show: !this.state.addTodoModal.show, working: false, isEditing },
          addTodoForm
        })
      },
    },
    addTodoForm: { ...this.defaultTodoFields },
    todocdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'todo.ib_tok_todo_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'todo.ib_tok_todo_desc',
          column: 'ib_tok_todo_desc',
          name: 'Journal Title',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },


    addPresModal: {
      show: false,
      working: false,
      isEditing: false,
      toggle: (addPresForm = { ...this.defaultPresFields }, isEditing = false) => {
        this.setState({
          addPresModal: { ...this.state.addPresModal, show: !this.state.addPresModal.show, working: false, isEditing },
          addPresForm
        })
      },
    },
    addPresForm: { ...this.defaultPresFields },
    prescdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'pres.ib_tok_pres_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'pres.ib_tok_pres_title',
          column: 'ib_tok_pres_title',
          name: 'Presentation Intro',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },

    docscdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'tokdocs.ib_tok_doc_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'tokdocs.ib_tok_doc_id',
          column: 'ib_tok_doc_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'tokdocs.ib_tok_doc_caption',
          column: 'ib_tok_doc_caption',
          name: 'Document',
          visibility: true,
          sortable: true,
        },
        {
          id: 'tokdocs.ib_tok_doc_date',
          column: 'ib_tok_doc_date',
          name: 'Date',
          visibility: true,
          sortable: true,
        },
        {
          id: 'tokdocs.created_on',
          column: 'created_on',
          name: 'Created Date',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
    notescdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'toknotes.ib_tok_note_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'toknotes.ib_tok_note_id',
          column: 'ib_tok_note_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'toknotes.ib_tok_note',
          column: 'ib_tok_note',
          name: 'Note',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
    planningcdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'tokplanning.ib_tok_plan_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'tokplanning.ib_tok_plan_id',
          column: 'ib_tok_plan_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'tokplanning.ib_tok_plan_text',
          column: 'ib_tok_plan_text',
          name: 'Text',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
  }

  componentDidMount() {
    const { search, rows: limit, order, dir } = this.state.cdt;
    this.fetch({
      search,
      limit,
      order,
      dir,
      page: 0,
    });
    this.getSupervisors()
    this.getSubjects()
    this.getTopics()
  }

  getSubjects = async () => {
    this.setState({ subjects: null })
    const { authData, selectedChild } = this.props
    const { class_id, class_theme, agegroup_id } = selectedChild

    const params = {
      class_id,
      agegroup_id,
      class_theme,
    }

    try {
      const res = await GET('academic-subject-lessons/get-subjects', {
        params,
      })

      this.setState({
        acsData: res.data.data.map((v) => {
          return { ...v, label: v.acs_name, value: v.acs_id }
        }),
      })
    } catch (err) {
      toast.error('Something went wrong while fetching subjects!')
      console.log(err)
    }
  }

  getTopics = async () => {
    if (this.getTopicsReq) {
      this.getTopicsReq.abort();
    }
    this.getTopicsReq = new AbortController();
    const res = await GET('academic-ib-tok/get-topics', {
      signal: this.getTopicsReq.signal,
      params: {
        agegroup_id: this.props.selectedChild.agegroup_id
      },
    });
    if (res) {
      this.setState({ topicsData: res.data.map((v, i) => { return { ...v, label: v.ib_tok_topic_name, value: v.ib_tok_topic_id } }) })
    }
  }

  getSupervisors = async () => {
    if (this.getSupervisorsReq) {
      this.getSupervisorsReq.abort();
    }
    this.getSupervisorsReq = new AbortController();
    const res = await GET('academic-ib-tok/get-supervisors', {
      signal: this.getSupervisorsReq.signal,
      params: {
        class_id: this.props.selectedChild.class_id
      },
    });
    if (res) {
      this.setState({ getSupervisorsData: res.data.map((v, i) => { return { ...v, label: v.full_name, value: v.user_id } }) })
    }
  }

  fetch = async (params) => {
    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      },
    });

    if (this.fetchReq) {
      this.fetchReq.abort();
    }
    this.fetchReq = new AbortController();

    try {
      const res = await GET('academic-ib-tok/get', {
        signal: this.fetchReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        },
      });
    }
  };

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true,
      ibTOKData: { ...this.defaultFormFields }
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ibTOKData: { ...this.defaultFormFields }
    });
  };

  handleCloseReflectionListModal = () => {
    this.setState({
      showReflectionListModal: false,
    });
  };

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } });
  };

  exportExcel = async () => {
    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName =
      'IB TOK Export ' + moment().format('DD-MM-YYYY');
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  saveUpdate = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const ibTOKData = { ...this.state.ibTOKData };
    ibTOKData.ib_tok_supervisors = ibTOKData.ib_tok_supervisors.map((v) => v.value)
    ibTOKData.ib_tok_acs = (ibTOKData.ib_tok_acs) ? ibTOKData.ib_tok_acs.value : null
    ibTOKData.ib_tok_topic_id = (ibTOKData.ib_tok_topic_id) ? ibTOKData.ib_tok_topic_id.value : null


    const fData = new FormData();
    fData.append('ibTOKData', JSON.stringify(ibTOKData));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);

    try {
      const res = await POST('academic-ib-tok/save', fData);
      if (res.data.type) {
        this.setState({
          working: false,
          showFormModal: false,
          isEditing: false,
          ibTOKData: this.defaultFormFields,
        });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  };

  edit = (data) => {
    let thisData = JSON.parse(JSON.stringify(data))
    let ib_tok_supervisors = []
    JSON.parse(thisData.ib_tok_supervisors).map((v) => {
      let findSup = this.state.getSupervisorsData.find((vv) => vv.user_id == v)
      if (findSup) {
        ib_tok_supervisors.push(findSup)
      }
    })
    let findAcs = this.state.acsData.find((v, i) => v.value == thisData.ib_tok_acs)
    if (findAcs) {
      thisData.ib_tok_acs = findAcs
    }

    let findTopic = this.state.topicsData.find((v, i) => v.value == thisData.ib_tok_topic_id)
    if (findTopic) {
      thisData.ib_tok_topic_id = findTopic
    }



    thisData.ib_tok_supervisors = ib_tok_supervisors
    this.setState({
      isEditing: true,
      showFormModal: true,
      ibTOKData: {
        ...thisData,
      }
    });
  }

  delete = async (data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: data.ib_tok_id, action: async () => {
          try {
            const res = await GET('academic-ib-tok/delete', {
              params: {
                ib_tok_id: data.ib_tok_id,
              },
            });
            this.setState({
              cdt: {
                ...this.state.cdt,
                data: this.state.cdt.data.filter((item) => item.uid !== data.uid),
              },
              deleteModal: {
                ...this.state.deleteModal, show: false
              }
            });
            toast.success(t(res.data.message));
          } catch (err) {
            console.log(err);
            toast.error(t('Something went wrong!'));
          }
        }
      }
    })

  };

  viewTOK = (data) => {

    let thisData = JSON.parse(JSON.stringify(data))
    let ib_tok_supervisors = []
    JSON.parse(thisData.ib_tok_supervisors).map((v) => {
      let findSup = this.state.getSupervisorsData.find((vv) => vv.user_id == v)
      if (findSup) {
        ib_tok_supervisors.push(findSup)
      }
    })
    thisData.ib_tok_supervisors = ib_tok_supervisors

    this.setState({
      showReflectionListModal: true,
      ibTOKData: {
        ...thisData,
      }
    }, () => {
      this.getTodos({
        search: '',
        limit: this.state.todocdt.rows,
        order: this.state.todocdt.order,
        dir: this.state.todocdt.dir,
        page: 0,
      });
      this.getPresentations({
        search: '',
        limit: this.state.prescdt.rows,
        order: this.state.prescdt.order,
        dir: this.state.prescdt.dir,
        page: 0,
      });
      this.getReflections({
        search: '',
        limit: this.state.refcdt.rows,
        order: this.state.refcdt.order,
        dir: this.state.refcdt.dir,
        page: 0,
      });
      this.getDocs({
        search: '',
        limit: this.state.docscdt.rows,
        order: this.state.docscdt.order,
        dir: this.state.docscdt.dir,
        page: 0,
      });
      this.getNotes({
        search: '',
        limit: this.state.notescdt.rows,
        order: this.state.notescdt.order,
        dir: this.state.notescdt.dir,
        page: 0,
      });
      this.getPlanning({
        search: '',
        limit: this.state.planningcdt.rows,
        order: this.state.planningcdt.order,
        dir: this.state.planningcdt.dir,
        page: 0,
      });
    });
  }

  getReflections = async (params) => {
    this.setState({
      refcdt: {
        ...this.state.refcdt,
        fetching: true,
      },
    });

    if (this.getReflectionsReq) {
      this.getReflectionsReq.abort();
    }
    this.getReflectionsReq = new AbortController();

    try {
      const res = await GET('academic-ib-tok/get-reflections', {
        signal: this.getReflectionsReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_tok_id: this.state.ibTOKData.ib_tok_id
        },
      });

      if (res.status === 200) {
        this.setState({
          refcdt: {
            ...this.state.refcdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        refcdt: {
          ...this.state.refcdt,
          fetching: false,
        },
      });
    }
  };

  getTodos = async (params) => {
    this.setState({
      todocdt: {
        ...this.state.todocdt,
        fetching: true,
      },
    });

    if (this.getTodosReq) {
      this.getTodosReq.abort();
    }
    this.getTodosReq = new AbortController();

    try {
      const res = await GET('academic-ib-tok/get-todos', {
        signal: this.getTodosReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_tok_id: this.state.ibTOKData.ib_tok_id
        },
      });

      if (res.status === 200) {
        this.setState({
          todocdt: {
            ...this.state.todocdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        todocdt: {
          ...this.state.todocdt,
          fetching: false,
        },
      });
    }
  };

  getPresentations = async (params) => {
    this.setState({
      prescdt: {
        ...this.state.prescdt,
        fetching: true,
      },
    });

    if (this.getPresentationsReq) {
      this.getPresentationsReq.abort();
    }
    this.getPresentationsReq = new AbortController();

    try {
      const res = await GET('academic-ib-tok/get-presentations', {
        signal: this.getPresentationsReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_tok_id: this.state.ibTOKData.ib_tok_id
        },
      });

      if (res.status === 200) {
        this.setState({
          prescdt: {
            ...this.state.prescdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        prescdt: {
          ...this.state.prescdt,
          fetching: false,
        },
      });
    }
  };

  handleCloseDocumentModal = () => {
    this.setState({
      showDocumentModal: false,
      editDocumentModal: false,
      ib_tok_doc_id: 0,
      ib_tok_doc_caption: '',
      ib_tok_doc_name: '',
      ib_tok_doc_date: new Date(),
    });
  }

  saveUpdateTOKDoc = async () => {
    const { ib_tok_doc_id, ib_tok_doc_caption, ib_tok_doc_name, ib_tok_doc_date, editDocumentModal } = this.state;
    const { ib_tok_id } = this.state.ibTOKData;

    if (!ib_tok_doc_caption) {
      toast.error(t('Please enter document caption'));
      return;
    }

    if (!editDocumentModal && !ib_tok_doc_name) {
      toast.error(t('Please select a file'));
      return;
    }

    this.setState({ TOKDocWorking: true });

    const fData = new FormData();
    fData.append('ib_tok_doc_id', ib_tok_doc_id);
    fData.append('ib_tok_doc_caption', ib_tok_doc_caption);
    fData.append('ib_tok_doc_name', ib_tok_doc_name);
    fData.append('ib_tok_doc_date', moment(ib_tok_doc_date).format('YYYY-MM-DD'));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);
    fData.append('ib_tok_id', ib_tok_id);

    try {
      const res = await POST('academic-ib-tok/save-tok-doc', fData);
      if (res.data.type) {
        this.setState({ TOKDocWorking: false });
        toast.success(t(res.data.message));
        this.getDocs({
          search: '',
          limit: this.state.docscdt.rows,
          order: this.state.docscdt.order,
          dir: this.state.docscdt.dir,
          page: 0,
        });
        this.handleCloseDocumentModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ TOKDocWorking: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ TOKDocWorking: false });
    }
  }

  getDocs = async (params) => {
    const { ib_tok_id } = this.state.ibTOKData;

    this.setState({
      docscdt: {
        ...this.state.docscdt,
        fetching: true,
      },
    });

    if (this.getDocsReq) {
      this.getDocsReq.abort();
    }
    this.getDocsReq = new AbortController();

    try {
      const res = await GET('academic-ib-tok/get-documents', {
        signal: this.getDocsReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_tok_id
        },
      });

      if (res.status === 200) {
        this.setState({
          docscdt: {
            ...this.state.docscdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        docscdt: {
          ...this.state.docscdt,
          fetching: false,
        },
      });
    }
  };

  editDoc = (data) => {
    this.setState({
      showDocumentModal: true,
      editDocumentModal: true,
      ib_tok_doc_caption: data.ib_tok_doc_caption,
      ib_tok_doc_date: new Date(data.ib_tok_doc_date),
      ib_tok_doc_id: data.ib_tok_doc_id,
    });
  };

  deleteDoc = async (data) => {
    try {
      const res = await GET('academic-ib-tok/delete-doc', {
        params: {
          ib_tok_doc_id: data.ib_tok_doc_id,
        },
      });
      this.setState({
        docscdt: {
          ...this.state.docscdt,
          data: this.state.docscdt.data.filter((item) => item.uid !== data.uid),
        },
      });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  setIBTOKFormValue = (column, value) => {
    let { ibTOKData } = this.state
    ibTOKData[column] = value
    this.setState({ ibTOKData })
  }

  setReflectionValue = (column, value) => {
    let { addReflectionForm } = this.state
    addReflectionForm[column] = value
    this.setState({ addReflectionForm })
  }

  addReflectionDetail = (ib_tok_refd_type) => {
    let { addReflectionForm } = this.state
    addReflectionForm['details'].push({ ...this.defaultReflectionDetailFields, ib_tok_refd_type })
    this.setState({ addReflectionForm })
  }

  setReflectionDetailValue = (index, column, value) => {
    let { addReflectionForm } = this.state
    addReflectionForm['details'][index][column] = value
    this.setState({ addReflectionForm })
  };

  saveUpdateReflection = async () => {
    const { addReflectionForm, addReflectionModal } = this.state;
    this.setState({ addReflectionModal: { ...addReflectionModal, working: true } });

    const fData = new FormData();
    addReflectionForm.details = addReflectionForm.details.map((v, i) => {
      if ((v.ib_tok_refd_type == 'File' || v.ib_tok_refd_type == 'Image') && v.ib_tok_refd_value?.name) {
        fData.append('reflectionFiles[' + i + ']', v.ib_tok_refd_value);
      }
      return { ...v }
    })
    fData.append('addReflectionForm', JSON.stringify(addReflectionForm));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);

    try {
      const res = await POST('academic-ib-tok/save-reflection', fData);
      if (res.data.type) {
        addReflectionModal.toggle()
        toast.success(t(res.data.message));
        this.getReflections({
          search: '',
          limit: this.state.refcdt.rows,
          order: this.state.refcdt.order,
          dir: this.state.refcdt.dir,
          page: 0,
        });
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  }

  editReflection = (data) => {
    console.log('data', data);
    this.state.addReflectionModal.toggle({
      ...data,
      ib_tok_ref_date: data.ib_tok_ref_date ? new Date(data.ib_tok_ref_date) : '',
      details: []
    }, true)
  }

  delRefDetail = async () => {
    const { id } = this.state.deleteModal;

    try {
      const res = await GET('academic-ib-tok/delete-reflection-detail', {
        params: {
          ib_tok_refd_id: id,
        },
      });
      this.getReflections({
        search: this.state.refcdt.search,
        limit: this.state.refcdt.rows,
        order: this.state.refcdt.order,
        dir: this.state.refcdt.dir,
        page: this.state.refcdt.page,
      });
      this.setState({ deleteModal: { show: false, id: 0, action: () => null } });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }






  setTodoValue = (column, value) => {
    let { addTodoForm } = this.state
    addTodoForm[column] = value
    this.setState({ addTodoForm })
  }

  addTodoDetail = (ib_tok_todod_type) => {
    let { addTodoForm } = this.state
    addTodoForm['details'].push({ ...this.defaultTodoDetailFields, ib_tok_todod_type })
    this.setState({ addTodoForm })
  }

  setTodoDetailValue = (index, column, value) => {
    let { addTodoForm } = this.state
    addTodoForm['details'][index][column] = value
    this.setState({ addTodoForm })
  };

  saveUpdateTodo = async () => {
    const { addTodoForm, addTodoModal } = this.state;
    this.setState({ addTodoModal: { ...addTodoModal, working: true } });

    const fData = new FormData();
    addTodoForm.details = addTodoForm.details.map((v, i) => {
      if ((v.ib_tok_todod_type == 'File' || v.ib_tok_todod_type == 'Image') && v.ib_tok_todod_value?.name) {
        fData.append('todoFiles[' + i + ']', v.ib_tok_todod_value);
      }
      return { ...v }
    })
    fData.append('addTodoForm', JSON.stringify(addTodoForm));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);

    try {
      const res = await POST('academic-ib-tok/save-todo', fData);
      if (res.data.type) {
        addTodoModal.toggle()
        toast.success(t(res.data.message));
        this.getTodos({
          search: '',
          limit: this.state.todocdt.rows,
          order: this.state.todocdt.order,
          dir: this.state.todocdt.dir,
          page: 0,
        });
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false, addTodoModal: { ...addTodoModal, working: false } });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));
      this.setState({ working: false, addTodoModal: { ...addTodoModal, working: false } });
    }
  }

  editTodo = (data) => {
    this.state.addTodoModal.toggle({
      ...data,
      ib_tok_todo_date: data.ib_tok_todo_date ? new Date(data.ib_tok_todo_date) : '',
      details: []
    }, true)
  }

  delTodoDetail = async () => {
    const { id } = this.state.deleteModal;

    try {
      const res = await GET('academic-ib-tok/delete-todo-detail', {
        params: {
          ib_tok_todod_id: id,
        },
      });
      this.getTodos({
        search: this.state.todocdt.search,
        limit: this.state.todocdt.rows,
        order: this.state.todocdt.order,
        dir: this.state.todocdt.dir,
        page: this.state.todocdt.page,
      });
      this.setState({ deleteModal: { show: false, id: 0, action: () => null } });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }


  setPresValue = (column, value) => {
    let { addPresForm } = this.state
    addPresForm[column] = value
    this.setState({ addPresForm })
  }

  addPresDetail = (ib_tok_presd_type) => {
    let { addPresForm } = this.state
    addPresForm['details'].push({ ...this.defaultPresDetailFields, ib_tok_presd_type })
    this.setState({ addPresForm })
  }

  setPresDetailValue = (index, column, value) => {
    let { addPresForm } = this.state
    addPresForm['details'][index][column] = value
    this.setState({ addPresForm })
  };

  saveUpdatePres = async () => {
    const { addPresForm, addPresModal } = this.state;
    this.setState({ addPresModal: { ...addPresModal, working: true } });

    const fData = new FormData();
    addPresForm.details = addPresForm.details.map((v, i) => {
      if ((v.ib_tok_presd_type == 'File' || v.ib_tok_presd_type == 'Image') && v.ib_tok_presd_value?.name) {
        fData.append('presFiles[' + i + ']', v.ib_tok_presd_value);
      }
      return { ...v }
    })
    fData.append('addPresForm', JSON.stringify(addPresForm));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);

    try {
      const res = await POST('academic-ib-tok/save-presentation', fData);
      if (res.data.type) {
        addPresModal.toggle()
        toast.success(t(res.data.message));
        this.getPresentations({
          search: '',
          limit: this.state.prescdt.rows,
          order: this.state.prescdt.order,
          dir: this.state.prescdt.dir,
          page: 0,
        });
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false, addPresModal: { ...addPresModal, working: false } });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));
      this.setState({ working: false, addPresModal: { ...addPresModal, working: false } });
    }
  }

  editPres = (data) => {
    this.state.addPresModal.toggle({
      ...data,
      ib_tok_pres_date: data.ib_tok_pres_date ? new Date(data.ib_tok_pres_date) : '',
      details: []
    }, true)
  }

  delPresDetail = async () => {
    const { id } = this.state.deleteModal;

    try {
      const res = await GET('academic-ib-tok/delete-pres-detail', {
        params: {
          ib_tok_presd_id: id,
        },
      });
      this.getPresentations({
        search: this.state.prescdt.search,
        limit: this.state.prescdt.rows,
        order: this.state.prescdt.order,
        dir: this.state.prescdt.dir,
        page: this.state.prescdt.page,
      });
      this.setState({ deleteModal: { show: false, id: 0, action: () => null } });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  getNotes = async (params) => {
    const { ib_tok_id } = this.state.ibTOKData;

    this.setState({
      notescdt: {
        ...this.state.notescdt,
        fetching: true,
      },
    });

    if (this.getNotesReq) {
      this.getNotesReq.abort();
    }
    this.getNotesReq = new AbortController();

    try {
      const res = await GET('academic-ib-tok/get-notes', {
        signal: this.getNotesReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_tok_id
        },
      });

      if (res.status === 200) {
        this.setState({
          notescdt: {
            ...this.state.notescdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        notescdt: {
          ...this.state.notescdt,
          fetching: false,
        },
      });
    }
  };

  handleCloseNoteModal = () => {
    this.setState({
      showNoteModal: false,
      editNoteModal: false,
      ib_tok_note_id: 0,
      ib_tok_note: ''
    });
  }

  saveUpdateTOKNote = async () => {
    const { ib_tok_id } = this.state.ibTOKData;
    const { ib_tok_note_id, ib_tok_note } = this.state;

    if (!ib_tok_note) {
      toast.error(t('Please enter note'));
      return;
    }


    this.setState({ TOKNoteWorking: true });

    const fData = new FormData();
    fData.append('ib_tok_note_id', ib_tok_note_id);
    fData.append('ib_tok_note', ib_tok_note);
    fData.append('child_user_id', this.props.selectedChild.child_user_id);
    fData.append('ib_tok_id', ib_tok_id);

    try {
      const res = await POST('academic-ib-tok/save-tok-note', fData);
      if (res.data.type) {
        this.setState({ TOKNoteWorking: false });
        toast.success(t(res.data.message));
        this.getNotes({
          search: '',
          limit: this.state.notescdt.rows,
          order: this.state.notescdt.order,
          dir: this.state.notescdt.dir,
          page: 0,
        });
        this.handleCloseNoteModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ TOKNoteWorking: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ TOKNoteWorking: false });
    }
  }

  editNote = (data) => {
    this.setState({
      showNoteModal: true,
      editNoteModal: true,
      ib_tok_note: data.ib_tok_note,
      ib_tok_note_id: data.ib_tok_note_id,
    });
  };

  deleteNote = async (data) => {
    try {
      const res = await GET('academic-ib-tok/delete-note', {
        params: {
          ib_tok_note_id: data.ib_tok_note_id,
        },
      });
      this.setState({
        notescdt: {
          ...this.state.notescdt,
          data: this.state.notescdt.data.filter((item) => item.uid !== data.uid),
        },
      });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  getPlanning = async (params) => {
    const { ib_tok_id } = this.state.ibTOKData;

    this.setState({
      planningcdt: {
        ...this.state.planningcdt,
        fetching: true,
      },
    });

    if (this.getPlanningReq) {
      this.getPlanningReq.abort();
    }
    this.getPlanningReq = new AbortController();

    try {
      const res = await GET('academic-ib-tok/get-planning', {
        signal: this.getPlanningReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_tok_id
        },
      });

      if (res.status === 200) {
        this.setState({
          planningcdt: {
            ...this.state.planningcdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        planningcdt: {
          ...this.state.planningcdt,
          fetching: false,
        },
      });
    }
  };

  handleClosePlanningModal = () => {
    this.setState({
      showPlanningModal: false,
      editPlanningModal: false,
      ib_tok_plan_id: 0,
      ib_tok_plan_text: '',
      ib_tok_plan_date: new Date(),
    });
  }

  saveUpdatetokplanning = async () => {
    const { ib_tok_id } = this.state.ibTOKData;
    const { ib_tok_plan_id, ib_tok_plan_text, ib_tok_plan_date } = this.state;

    if (!ib_tok_plan_text) {
      toast.error(t('Please enter text'));
      return;
    }

    this.setState({ TOKPlanningWorking: true });

    const fData = new FormData();
    fData.append('ib_tok_plan_id', ib_tok_plan_id);
    fData.append('ib_tok_plan_text', ib_tok_plan_text);
    fData.append('ib_tok_plan_date', moment(ib_tok_plan_date).format('YYYY-MM-DD'));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);
    fData.append('ib_tok_id', ib_tok_id);

    try {
      const res = await POST('academic-ib-tok/save-tok-planning', fData);
      if (res.data.type) {
        this.setState({ TOKPlanningWorking: false });
        toast.success(t(res.data.message));
        this.getPlanning({
          search: '',
          limit: this.state.planningcdt.rows,
          order: this.state.planningcdt.order,
          dir: this.state.planningcdt.dir,
          page: 0,
        });
        this.handleClosePlanningModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ TOKPlanningWorking: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ TOKPlanningWorking: false });
    }
  }

  editPlanning = (data) => {
    this.setState({
      showPlanningModal: true,
      editPlanningModal: true,
      ib_tok_plan_id: data.ib_tok_plan_id,
      ib_tok_plan_text: data.ib_tok_plan_text,
      ib_tok_plan_date: new Date(data.ib_tok_plan_date),
    });
  };

  deletePlanning = async (data) => {
    try {
      const res = await GET('academic-ib-tok/delete-planning', {
        params: {
          ib_tok_plan_id: data.ib_tok_plan_id,
        },
      });
      this.setState({
        planningcdt: {
          ...this.state.planningcdt,
          data: this.state.planningcdt.data.filter((item) => item.uid !== data.uid),
        },
      });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { addReflectionModal } = this.state;

    let tdData;

    // Action Buttons
    if (column_id == 'ib_tok_id') {
      tdData = (
        <>
          <Button
            onClick={() => this.delete(dataObj)}
            title={t('Delete')}
            variant='danger'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            onClick={() => this.edit(dataObj)}
            title={t('Edit')}
            variant='warning'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            title='View TOK'
            onClick={() => this.viewTOK(dataObj)}
            variant='success'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faFileLines} />
          </Button>

        </>
      );
    } else if (column_id == 'ib_tok_approved' || column_id == 'ib_tok_completed') {
      tdData = <div className={(column_data) ? 'badge badge-light-success' : 'badge badge-light-danger'}>{(column_data) ? 'Yes' : 'No'}</div>
    } else {
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  };

  render() {
    let { ibTOKData, getSupervisorsData, addReflectionModal, addTodoModal, addTodoForm, addPresModal, addPresForm, addReflectionForm, acsData, topicsData } = this.state
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('IB Theory Of Knowledge')} backBtn />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Header
                  style={{
                    backgroundColor: '#fff',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  }}>
                  <Row>
                    <Col md={6} className='d-flex align-items-center'>
                      <ButtonGroup size='sm' className='me-3 float-left'>
                        {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                        <Button onClick={() => this.exportExcel()}>
                          <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                        </Button>
                        {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                        <DropdownButton
                          autoClose={'outside'}
                          size='sm'
                          as={ButtonGroup}
                          title={t('Column Visibility')}>
                          {this.state.cdt.columns.map((column, column_index) => (
                            <Dropdown.Item
                              key={column_index}
                              className={
                                column.visibility
                                  ? 'column-name-item'
                                  : 'column-name-item active'
                              }
                              onClick={() => this.showHideColumn(column_index)}>
                              <FontAwesomeIcon
                                icon={column.visibility ? faEye : faEyeSlash}
                              />{' '}
                              {column.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </ButtonGroup>
                      <div style={{ display: 'inline-block' }}>
                        {t('Show')}
                        <Form.Select
                          value={this.state.cdt.rows}
                          size='sm'
                          onChange={(e) =>
                            this.fetch({
                              search: this.state.cdt.search,
                              limit: e.target.value,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: this.state.cdt.page,
                            })
                          }
                          style={{
                            width: 70,
                            marginInline: 5,
                            display: 'inline-block',
                          }}>
                          {this.state.cdt.rowsOptions.map((row, row_index) => (
                            <option key={row_index}>{row}</option>
                          ))}
                        </Form.Select>
                        {t('Records')}
                      </div>
                    </Col>
                    <Col md={6} className='d-flex justify-content-end'>
                      <div className='cdt-search'>
                        <input
                          type='text'
                          ref={this.searchFieldRef}
                          defaultValue={this.state.cdt.search}
                          onChange={(e) =>
                            this.fetch({
                              search: e.target.value,
                              limit: this.state.cdt.rows,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: 0,
                            })
                          }
                          placeholder={t('Search...')}
                        />
                        <button
                          onClick={() => {
                            this.fetch({
                              search: '',
                              limit: this.state.cdt.rows,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: 0,
                            });
                            this.searchFieldRef.current.value = '';
                          }}>
                          <FontAwesomeIcon
                            icon={this.state.cdt.search == '' ? faSearch : faXmark}
                            color='#000'
                          />
                        </button>
                      </div>
                      <button
                        onClick={this.handleOpenFormModal}
                        className='btn btn-success btn-sm ms-3'>
                        <FontAwesomeIcon
                          icon={faPlus}
                          color='#fff'
                          style={{ fontSize: 12 }}
                        />
                      </button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body style={{ paddingTop: 8 }}>
                  <Row>
                    {this.state.cdt.fetching && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' />{' '}
                        {t('Loading Data')}
                      </Col>
                    )}
                    {!this.state.cdt.fetching && this.state.cdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                    {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            {renderTableHead(this)}
                            {renderTableBody(this)}
                          </Table>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Pagination
                    pageCount={this.state.cdt.lastPage || 1}
                    forcePage={this.state.cdt.page}
                    callbackParams={{
                      search: this.state.cdt.search,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                    }}
                    callback={this.fetch}
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        {/* NEW/UPDATE MODAL IB TOK FORM */}
        <Modal backdrop='static' keyboard={false} size={'lg'} show={this.state.showFormModal} onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.isEditing ? t('Edit') : t('Add')} {t('IB TOK')}
            </Modal.Title>
          </Modal.Header>
          {this.state.showFormModal &&
            <Modal.Body>
              <Row>
                <Col md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Title')} <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={ibTOKData.ib_tok_name}
                      onChange={(e) =>
                        this.setIBTOKFormValue('ib_tok_name', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Subject')} <span className='text-danger'>*</span></Form.Label>
                    <Select
                      classNamePrefix='custom-styled-select'
                      options={acsData}
                      onChange={(event) => this.setIBTOKFormValue('ib_tok_acs', event)}
                      value={ibTOKData.ib_tok_acs}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Topic')} <span className='text-danger'>*</span></Form.Label>
                    <Select
                      classNamePrefix='custom-styled-select'
                      options={topicsData}
                      onChange={(event) => this.setIBTOKFormValue('ib_tok_topic_id', event)}
                      value={ibTOKData.ib_tok_topic_id}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Topic')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='2'
                      defaultValue={ibTOKData.ib_tok_topic}
                      onChange={(e) =>
                        this.setIBTOKFormValue('ib_tok_topic', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col> */}
                {/* <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Research Question')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='2'
                      defaultValue={ibTOKData.ib_tok_research_question}
                      onChange={(e) =>
                        this.setIBTOKFormValue('ib_tok_research_question', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col> */}
                <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Notes')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='2'
                      defaultValue={ibTOKData.ib_tok_note}
                      onChange={(e) =>
                        this.setIBTOKFormValue('ib_tok_note', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Supervisors')}</Form.Label>
                    <Select
                      classNamePrefix='custom-styled-select'
                      isMulti
                      closeMenuOnSelect={false}
                      options={getSupervisorsData}
                      onChange={(event) => this.setIBTOKFormValue('ib_tok_supervisors', event)}
                      value={ibTOKData.ib_tok_supervisors}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}

                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.working}
              variant={this.state.isEditing ? 'warning' : 'success'}
              onClick={() => this.saveUpdate()}>
              {this.state.isEditing ? t('Update') : t('Save')}{' '}
              {this.state.working && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* REFLECTION MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} size={'lg'} show={addReflectionModal.show} onHide={addReflectionModal.toggle}>
          <Modal.Header closeButton>
            <Modal.Title>
              {addReflectionModal.isEditing ? t('Edit') : t('Add')} {t('Journal')}
            </Modal.Title>
          </Modal.Header>
          {addReflectionModal.show &&
            <Modal.Body>
              <Row>
                <Col md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Title')} <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={addReflectionForm.ib_tok_ref_title}
                      onChange={(e) =>
                        this.setReflectionValue('ib_tok_ref_title', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Date')}</Form.Label>
                    <DatePicker
                      selected={addReflectionForm.ib_tok_ref_date}
                      onChange={(date) =>
                        this.setReflectionValue('ib_tok_ref_date', date)
                      }
                      selectsStart
                      dateFormat={'dd-MM-yyyy'}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className='ck-editor-ht-200'>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col> <Form.Label>{t('Notes')}</Form.Label></Col>
                    </Row>
                    <CKEditor
                      config={{
                        removePlugins: ['Title'],
                        toolbar: {
                          items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'undo',
                            'redo'
                          ]
                        }
                      }}
                      editor={Editor}
                      data={addReflectionForm.ib_tok_ref_desc}
                      onChange={(
                        event,
                        editor
                      ) => {
                        const ib_tok_ref_desc = editor.getData();
                        this.setReflectionValue('ib_tok_ref_desc', ib_tok_ref_desc);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* {!addReflectionModal.isEditing && */}
              <Row>
                <Col className='d-flex flex-row'>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addReflectionDetail('URL')}>Add URL</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addReflectionDetail('Image')}>Add Image</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addReflectionDetail('File')}>Add File</Button>
                </Col>
              </Row>
              {/* } */}
              <Row>
                {addReflectionForm.details.map((v, i) => {
                  return <Col key={i} md="6">
                    <div className='border p-1 m-1'>
                      <Form.Group className='mb-3'>
                        <div className='badge badge-light-danger me-1'>{t('Journal')}{' ' + (i + 1) + ':'}</div>
                        <Form.Label>
                          {t('Title')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          defaultValue={v.ib_tok_refd_name}
                          onChange={(e) =>
                            this.setReflectionDetailValue(i, 'ib_tok_refd_name', e.target.value)
                          }
                        />
                      </Form.Group>
                      {(v.ib_tok_refd_type == 'URL') &&
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('URL')}</Form.Label>
                          <Form.Control
                            type='text'
                            defaultValue={v.ib_tok_refd_value}
                            onChange={(e) =>
                              this.setReflectionDetailValue(i, 'ib_tok_refd_value', e.target.value)
                            }
                          />
                        </Form.Group>
                      }
                      {(v.ib_tok_refd_type == 'Image' || v.ib_tok_refd_type == 'File') &&
                        <Form.Group controlId='formFile'>
                          <Form.Label>
                            {v.ib_tok_refd_type + ' ' + t('Attachment')}
                          </Form.Label>
                          <Form.Control
                            type='file'
                            onChange={(e) => this.setReflectionDetailValue(i, 'ib_tok_refd_value', e.target.files[0])}
                          />
                        </Form.Group>
                      }
                      {(v.ib_tok_refd_type == 'Image' && v.ib_tok_refd_value && (typeof v.ib_tok_refd_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <img
                            alt=''
                            style={{ maxWidth: 270, height: '100%', }}
                            src={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_reflection_details/' + v.ib_tok_refd_value}
                          />
                        </div>
                      )}
                      {(v.ib_tok_refd_type == 'File' && v.ib_tok_refd_value && (typeof v.ib_tok_refd_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <a href={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_reflection_details/' + v.ib_tok_refd_value} target='_blank'>Open File</a>
                        </div>
                      )}
                    </div>
                  </Col>
                })}
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant='secondary' onClick={addReflectionModal.toggle}>
              {t('Close')}
            </Button>
            <Button
              disabled={addReflectionModal.working}
              variant={addReflectionModal.isEditing ? 'warning' : 'success'}
              onClick={() => this.saveUpdateReflection()}>
              {addReflectionModal.isEditing ? t('Update') : t('Save')}{' '}
              {addReflectionModal.working && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* TODO MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} size={'lg'} show={addTodoModal.show} onHide={addTodoModal.toggle}>
          <Modal.Header closeButton>
            <Modal.Title>
              {addTodoModal.isEditing ? t('Edit') : t('Add')} {t('Todo')}
            </Modal.Title>
          </Modal.Header>
          {addTodoModal.show &&
            <Modal.Body>
              <Row>
                <Col md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Title')} <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={addTodoForm.ib_tok_todo_desc}
                      onChange={(e) =>
                        this.setTodoValue('ib_tok_todo_desc', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Date')}</Form.Label>
                    <DatePicker
                      selected={addTodoForm.ib_tok_todo_date}
                      onChange={(date) =>
                        this.setTodoValue('ib_tok_todo_date', date)
                      }
                      selectsStart
                      dateFormat={'dd-MM-yyyy'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* {!addTodoModal.isEditing && */}
              <Row>
                <Col className='d-flex flex-row'>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addTodoDetail('URL')}>Add URL</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addTodoDetail('Image')}>Add Image</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addTodoDetail('File')}>Add File</Button>
                </Col>
              </Row>
              {/* } */}
              <Row>
                {addTodoForm.details.map((v, i) => {
                  return <Col key={i} md="6">
                    <div className='border p-1 m-1'>
                      <Form.Group className='mb-3'>
                        <div className='badge badge-light-danger me-1'>{t('Todo')}{' ' + (i + 1) + ':'}</div>
                        <Form.Label>
                          {t('Title')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          defaultValue={v.ib_tok_todod_name}
                          onChange={(e) =>
                            this.setTodoDetailValue(i, 'ib_tok_todod_name', e.target.value)
                          }
                        />
                      </Form.Group>
                      {(v.ib_tok_todod_type == 'URL') &&
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('URL')}</Form.Label>
                          <Form.Control
                            type='text'
                            defaultValue={v.ib_tok_todod_value}
                            onChange={(e) =>
                              this.setTodoDetailValue(i, 'ib_tok_todod_value', e.target.value)
                            }
                          />
                        </Form.Group>
                      }
                      {(v.ib_tok_todod_type == 'Image' || v.ib_tok_todod_type == 'File') &&
                        <Form.Group controlId='formFile'>
                          <Form.Label>
                            {v.ib_tok_todod_type + ' ' + t('Attachment')}
                          </Form.Label>
                          <Form.Control
                            type='file'
                            onChange={(e) => this.setTodoDetailValue(i, 'ib_tok_todod_value', e.target.files[0])}
                          />
                        </Form.Group>
                      }
                      {(v.ib_tok_todod_type == 'Image' && v.ib_tok_todod_value && (typeof v.ib_tok_todod_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <img
                            alt=''
                            style={{ maxWidth: 270, height: '100%', }}
                            src={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_todo_details/' + v.ib_tok_todod_value}
                          />
                        </div>
                      )}
                      {(v.ib_tok_todod_type == 'File' && v.ib_tok_todod_value && (typeof v.ib_tok_todod_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <a href={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_todo_details/' + v.ib_tok_todod_value} target='_blank'>Open File</a>
                        </div>
                      )}
                    </div>
                  </Col>
                })}
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant='secondary' onClick={addTodoModal.toggle}>
              {t('Close')}
            </Button>
            <Button
              disabled={addTodoModal.working}
              variant={addTodoModal.isEditing ? 'warning' : 'success'}
              onClick={() => this.saveUpdateTodo()}>
              {addTodoModal.isEditing ? t('Update') : t('Save')}{' '}
              {addTodoModal.working && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>



        {/* TOK MODAL */}
        <Modal
          backdrop='static'
          keyboard={false}
          fullscreen
          show={this.state.showReflectionListModal}
          onHide={this.handleCloseReflectionListModal}>
          <Modal.Header style={{ backgroundColor: '#15bbe7' }} closeButton>
            <Modal.Title style={{ color: '#fff' }}>
              {t('IB Theory of Knowledge')}
            </Modal.Title>
          </Modal.Header>
          {this.state.showReflectionListModal &&
            <Modal.Body>
              <Row>
                <Col md={6} className='mb-3'>
                  <strong>{t('Title')}</strong><br />
                  {ibTOKData.ib_tok_name}
                </Col>
                <Col md={6} className='mb-3'>
                  <strong>{t('Subject')}</strong><br />
                  {ibTOKData.acs_name}
                </Col>
                <Col md={6} className='mb-3'>
                  <strong>{t('Topic')}</strong><br />
                  {ibTOKData.ib_tok_topic_name}<br />
                  {ibTOKData.ib_tok_topic_description}
                </Col>
                <Col md={6} className='mb-3'>
                  <strong>{t('Notes')}</strong><br />
                  {ibTOKData.ib_tok_note}
                </Col>
                <Col md={12}>
                  <strong>{t('Supervisors')}</strong><br />
                  {ibTOKData.ib_tok_supervisors.map(item => item.label).join(', ')}
                </Col>
              </Row>

              <div className='p-3 mt-3' style={{ backgroundColor: '#f9f9f9', borderRadius: 10 }}>
                <div style={{ position: 'absolute', right: 30 }}>
                  {this.state.activeTab == 'todo' &&
                    <Button
                      onClick={() => addTodoModal.toggle({ ...this.defaultTodoFields, ib_tok_id: this.state.ibTOKData.ib_tok_id, details: [] }, false)}
                      variant='success'
                      size='sm'
                      className='me-1'>
                      <FontAwesomeIcon icon={faPlus} /> {t('Add Todo')}
                    </Button>
                  }
                  {this.state.activeTab == 'pres' &&
                    <Button
                      onClick={() => addPresModal.toggle({ ...this.defaultPresFields, ib_tok_id: this.state.ibTOKData.ib_tok_id, details: [] }, false)}
                      variant='success'
                      size='sm'
                      className='me-1'>
                      <FontAwesomeIcon icon={faPlus} /> {t('Add Presentation')}
                    </Button>
                  }
                  {this.state.activeTab == 'documents' &&
                    <Button
                      onClick={() => this.setState({ showDocumentModal: true })}
                      variant='success'
                      size='sm'
                      className='me-1'>
                      <FontAwesomeIcon icon={faPlus} /> {t('Add Document')}
                    </Button>
                  }
                  {this.state.activeTab == 'reflections' &&
                    <Button
                      onClick={() => addReflectionModal.toggle({ ...this.defaultReflectionFields, ib_tok_id: this.state.ibTOKData.ib_tok_id, details: [] }, false)}
                      variant='success'
                      size='sm'
                      className='me-1'>
                      <FontAwesomeIcon icon={faPlus} /> {t('Add Journal')}
                    </Button>
                  }
                  {this.state.activeTab == 'notes' &&
                    <Button
                      onClick={() => this.setState({ showNoteModal: true })}
                      variant='success'
                      size='sm'
                      className='me-1'>
                      <FontAwesomeIcon icon={faPlus} /> {t('Add Note')}
                    </Button>
                  }
                  {(this.state.activeTab == 'planning' && this.state.planningcdt.data.length < 3) &&
                    <Button
                      onClick={() => this.setState({ showPlanningModal: true })}
                      variant='success'
                      size='sm'
                      className='me-1'>
                      <FontAwesomeIcon icon={faPlus} /> {t('Add Plan')}
                    </Button>
                  }
                </div>
                <Tabs
                  defaultActiveKey={this.state.activeTab}
                  id="cas-tabs"
                  className="mb-3 cas-tabs"
                  onSelect={(activeTab) => this.setState({ activeTab })}>

                  {/* TAB DOCUMENTS */}
                  <Tab className='cas-tab-content' eventKey="documents" title={t('Documents')}>
                    <Row>
                      {this.state.docscdt.fetching && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          <Spinner as='span' animation='grow' size='sm' />{' '}
                          {t('Loading Data')}
                        </Col>
                      )}
                      {!this.state.docscdt.fetching && this.state.docscdt.data.length === 0 && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          {t('No Data Found')}
                        </Col>
                      )}
                      {!this.state.docscdt.fetching && this.state.docscdt.data.length !== 0 && (
                        <Col md={12} className='pt-2'>
                          <div
                            id='datatable-list'
                            className='mb-3'
                            style={{ width: 'initial' }}>
                            <Table responsive>
                              <tbody>
                                {this.state.docscdt.data.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ width: 85 }}>
                                      <Button variant='warning' className='me-1'
                                        onClick={() => this.editDoc(item)}
                                        size='sm'>
                                        <FontAwesomeIcon icon={faPencil} />
                                      </Button>
                                      <Button variant='danger'
                                        onClick={() => this.deleteDoc(item)}
                                        size='sm'>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </td>
                                    <td style={{ width: 100 }}>
                                      <Badge bg="dark" style={{ marginTop: 5 }}>
                                        <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.ib_tok_doc_date).format('DD-MM-YYYY')}
                                      </Badge></td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      <Button variant='link' className='p-0' onClick={() => {
                                        let url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_documents/' + item.ib_tok_doc_name;
                                        window.open(url, '_blank');
                                      }}>
                                        {item.ib_tok_doc_caption}
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Pagination
                      pageCount={this.state.docscdt.lastPage || 1}
                      forcePage={this.state.docscdt.page}
                      callbackParams={{
                        search: this.state.docscdt.search,
                        limit: this.state.docscdt.rows,
                        order: this.state.docscdt.order,
                        dir: this.state.docscdt.dir,
                      }}
                      callback={this.getDocs}
                    />
                  </Tab>

                  {/* TAB REFLECTIONS */}
                  <Tab eventKey="reflections" title={t('Journal')}>
                    <Row>
                      {this.state.refcdt.fetching && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          <Spinner as='span' animation='grow' size='sm' />{' '}
                          {t('Loading Data')}
                        </Col>
                      )}
                      {!this.state.refcdt.fetching && this.state.refcdt.data.length === 0 && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          {t('No Data Found')}
                        </Col>
                      )}
                      {!this.state.refcdt.fetching && this.state.refcdt.data.length !== 0 && (
                        <Col md={12} className='pt-2'>
                          <div
                            id='datatable-list'
                            className='mb-3'
                            style={{ width: 'initial' }}>
                            <Table responsive>
                              <tbody>
                                {this.state.refcdt.data.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className='p-1'>
                                        <Row>
                                          <Col md={6} className='mb-2'>
                                            <Button variant='warning' className='me-2'
                                              onClick={() => this.editReflection(item)}
                                              size='sm'>
                                              <FontAwesomeIcon icon={faPencil} />
                                            </Button>
                                            {item.ib_tok_ref_date &&
                                              <Badge bg="dark" style={{ marginRight: 10 }}>
                                                <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.ib_tok_ref_date).format('DD-MM-YYYY')}
                                              </Badge>
                                            }
                                            {item.ib_tok_ref_title}
                                          </Col>
                                          <Col md={6} className='text-end mb-2'>
                                            {item.ib_tok_ref_approved == 1 &&
                                              <Badge bg='success' className='me-2'><FontAwesomeIcon icon={faCheck} /> {t('Approved')}</Badge>
                                            }
                                            <Badge bg="info" className='me-2'>
                                              <FontAwesomeIcon className='me-1' icon={faUser} /> {item.created_by_name}
                                            </Badge>
                                          </Col>
                                          <Col md={12} className='mb-3'>
                                            <div dangerouslySetInnerHTML={{ __html: item.ib_tok_ref_desc }} />
                                            {item.ib_tok_ref_target_outcome && JSON.parse(item.ib_tok_ref_target_outcome).map((item, index) => <Badge key={index} bg='warning' className='me-2'>{item}</Badge>)}
                                          </Col>
                                          <Col md={12}>
                                            {item.reflection_details.map((item, index) => {
                                              let icon = null;
                                              let url = null;

                                              if (item.ib_tok_refd_type == 'URL') {
                                                icon = faGlobe;
                                                url = item.ib_tok_refd_value;
                                              } else if (item.ib_tok_refd_type == 'Image') {
                                                icon = faImage;
                                                url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_reflection_details/' + item.ib_tok_refd_value;
                                              } else if (item.ib_tok_refd_type == 'File') {
                                                icon = faFolder;
                                                url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_reflection_details/' + item.ib_tok_refd_value;
                                              }

                                              const name = item.ib_tok_refd_name ? item.ib_tok_refd_name : item.ib_tok_refd_type;

                                              return (<div style={{ display: 'inline-block', position: 'relative' }} key={index}>
                                                <Button variant='danger' size="sm" style={{ width: 20, height: 20, borderRadius: 10, padding: 0, lineHeight: '10px', position: 'absolute', top: -7, right: 5 }} onClick={() => this.setState({ deleteModal: { show: true, id: item.ib_tok_refd_id, action: this.delRefDetail } })}>
                                                  <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 10 }} />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    window.open(url, '_blank');
                                                  }}
                                                  key={index}
                                                  variant='outline-dark'
                                                  size='sm'
                                                  className='me-3'>
                                                  <FontAwesomeIcon icon={icon} /> {name}
                                                </Button>
                                              </div>)
                                            })}
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Pagination
                      pageCount={this.state.refcdt.lastPage || 1}
                      forcePage={this.state.refcdt.page}
                      callbackParams={{
                        search: this.state.refcdt.search,
                        limit: this.state.refcdt.rows,
                        order: this.state.refcdt.order,
                        dir: this.state.refcdt.dir,
                      }}
                      callback={this.getReflections}
                    />
                  </Tab>

                  {/* TAB REFLECTIONS */}
                  <Tab eventKey="todo" title={t('Todo')}>
                    <Row>
                      {this.state.todocdt.fetching && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          <Spinner as='span' animation='grow' size='sm' />{' '}
                          {t('Loading Data')}
                        </Col>
                      )}
                      {!this.state.todocdt.fetching && this.state.todocdt.data.length === 0 && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          {t('No Data Found')}
                        </Col>
                      )}
                      {!this.state.todocdt.fetching && this.state.todocdt.data.length !== 0 && (
                        <Col md={12} className='pt-2'>
                          <div
                            id='datatable-list'
                            className='mb-3'
                            style={{ width: 'initial' }}>
                            <Table responsive>
                              <tbody>
                                {this.state.todocdt.data.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className='p-1'>
                                        <Row>
                                          <Col md={6} className='mb-2'>
                                            <Button variant='warning' className='me-2'
                                              onClick={() => this.editTodo(item)}
                                              size='sm'>
                                              <FontAwesomeIcon icon={faPencil} />
                                            </Button>
                                            {item.ib_tok_todo_date &&
                                              <Badge bg="dark" style={{ marginRight: 10 }}>
                                                <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.ib_tok_todo_date).format('DD-MM-YYYY')}
                                              </Badge>
                                            }
                                            {item.ib_tok_todo_desc}
                                          </Col>
                                          <Col md={6} className='text-end mb-2'>
                                            {item.ib_tok_todo_approved == 1 &&
                                              <Badge bg='success' className='me-2'><FontAwesomeIcon icon={faCheck} /> {t('Approved')}</Badge>
                                            }
                                            <Badge bg="info" className='me-2'>
                                              <FontAwesomeIcon className='me-1' icon={faUser} /> {item.created_by_name}
                                            </Badge>
                                          </Col>

                                          <Col md={12}>
                                            {item.todo_details.map((item, index) => {
                                              let icon = null;
                                              let url = null;

                                              if (item.ib_tok_todod_type == 'URL') {
                                                icon = faGlobe;
                                                url = item.ib_tok_todod_value;
                                              } else if (item.ib_tok_todod_type == 'Image') {
                                                icon = faImage;
                                                url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_todo_details/' + item.ib_tok_todod_value;
                                              } else if (item.ib_tok_todod_type == 'File') {
                                                icon = faFolder;
                                                url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_todo_details/' + item.ib_tok_todod_value;
                                              }

                                              const name = item.ib_tok_todod_name ? item.ib_tok_todod_name : item.ib_tok_todod_type;

                                              return (<div style={{ display: 'inline-block', position: 'relative' }} key={index}>
                                                <Button variant='danger' size="sm" style={{ width: 20, height: 20, borderRadius: 10, padding: 0, lineHeight: '10px', position: 'absolute', top: -7, right: 5 }} onClick={() => this.setState({ deleteModal: { show: true, id: item.ib_tok_todod_id, action: this.delTodoDetail } })}>
                                                  <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 10 }} />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    window.open(url, '_blank');
                                                  }}
                                                  key={index}
                                                  variant='outline-dark'
                                                  size='sm'
                                                  className='me-3'>
                                                  <FontAwesomeIcon icon={icon} /> {name}
                                                </Button>
                                              </div>)
                                            })}
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Pagination
                      pageCount={this.state.todocdt.lastPage || 1}
                      forcePage={this.state.todocdt.page}
                      callbackParams={{
                        search: this.state.todocdt.search,
                        limit: this.state.todocdt.rows,
                        order: this.state.todocdt.order,
                        dir: this.state.todocdt.dir,
                      }}
                      callback={this.getTodos}
                    />
                  </Tab>

                  {/* TAB PRESENTATION */}
                  <Tab eventKey="pres" title={t('Presentation')}>
                    <Row>
                      {this.state.prescdt.fetching && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          <Spinner as='span' animation='grow' size='sm' />{' '}
                          {t('Loading Data')}
                        </Col>
                      )}
                      {!this.state.prescdt.fetching && this.state.prescdt.data.length === 0 && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          {t('No Data Found')}
                        </Col>
                      )}
                      {!this.state.prescdt.fetching && this.state.prescdt.data.length !== 0 && (
                        <Col md={12} className='pt-2'>
                          <div
                            id='datatable-list'
                            className='mb-3'
                            style={{ width: 'initial' }}>
                            <Table responsive>
                              <tbody>
                                {this.state.prescdt.data.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div className='p-1'>
                                        <Row>
                                          <Col md={6} className='mb-2'>
                                            <Button variant='warning' className='me-2'
                                              onClick={() => this.editPres(item)}
                                              size='sm'>
                                              <FontAwesomeIcon icon={faPencil} />
                                            </Button>
                                            {item.ib_tok_pres_date &&
                                              <Badge bg="dark" style={{ marginRight: 10 }}>
                                                <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.ib_tok_pres_date).format('DD-MM-YYYY')}
                                              </Badge>
                                            }
                                            {item.ib_tok_pres_title}
                                          </Col>
                                          <Col md={6} className='text-end mb-2'>
                                            {item.ib_tok_pres_approved == 1 &&
                                              <Badge bg='success' className='me-2'><FontAwesomeIcon icon={faCheck} /> {t('Approved')}</Badge>
                                            }
                                            <Badge bg="info" className='me-2'>
                                              <FontAwesomeIcon className='me-1' icon={faUser} /> {item.created_by_name}
                                            </Badge>
                                          </Col>

                                          <Col md={12}>
                                            {item.pres_details.map((item, index) => {
                                              let icon = null;
                                              let url = null;

                                              if (item.ib_tok_presd_type == 'URL') {
                                                icon = faGlobe;
                                                url = item.ib_tok_presd_value;
                                              } else if (item.ib_tok_presd_type == 'Image') {
                                                icon = faImage;
                                                url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_presentation_details/' + item.ib_tok_presd_value;
                                              } else if (item.ib_tok_presd_type == 'File') {
                                                icon = faFolder;
                                                url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_presentation_details/' + item.ib_tok_presd_value;
                                              }

                                              const name = item.ib_tok_presd_name ? item.ib_tok_presd_name : item.ib_tok_presd_type;

                                              return (<div style={{ display: 'inline-block', position: 'relative' }} key={index}>
                                                <Button variant='danger' size="sm" style={{ width: 20, height: 20, borderRadius: 10, padding: 0, lineHeight: '10px', position: 'absolute', top: -7, right: 5 }} onClick={() => this.setState({ deleteModal: { show: true, id: item.ib_tok_presd_id, action: this.delPresDetail } })}>
                                                  <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 10 }} />
                                                </Button>
                                                <Button
                                                  onClick={() => {
                                                    window.open(url, '_blank');
                                                  }}
                                                  key={index}
                                                  variant='outline-dark'
                                                  size='sm'
                                                  className='me-3'>
                                                  <FontAwesomeIcon icon={icon} /> {name}
                                                </Button>
                                              </div>)
                                            })}
                                          </Col>
                                        </Row>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Pagination
                      pageCount={this.state.prescdt.lastPage || 1}
                      forcePage={this.state.prescdt.page}
                      callbackParams={{
                        search: this.state.prescdt.search,
                        limit: this.state.prescdt.rows,
                        order: this.state.prescdt.order,
                        dir: this.state.prescdt.dir,
                      }}
                      callback={this.getPresentations}
                    />
                  </Tab>

                  {/* TAB NOTES */}
                  <Tab eventKey="notes" title={t('Notes')}>
                    <Row>
                      {this.state.notescdt.fetching && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          <Spinner as='span' animation='grow' size='sm' />{' '}
                          {t('Loading Data')}
                        </Col>
                      )}
                      {!this.state.notescdt.fetching && this.state.notescdt.data.length === 0 && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          {t('No Data Found')}
                        </Col>
                      )}
                      {!this.state.notescdt.fetching && this.state.notescdt.data.length !== 0 && (
                        <Col md={12} className='pt-2'>
                          <div
                            id='datatable-list'
                            className='mb-3'
                            style={{ width: 'initial' }}>
                            <Table responsive>
                              <tbody>
                                {this.state.notescdt.data.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ width: 85 }}>
                                      <Button variant='warning' className='me-1'
                                        onClick={() => this.editNote(item)}
                                        size='sm'>
                                        <FontAwesomeIcon icon={faPencil} />
                                      </Button>
                                      <Button variant='danger'
                                        onClick={() => this.deleteNote(item)}
                                        size='sm'>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </td>
                                    <td style={{ width: 100 }}>
                                      <Badge bg="dark">
                                        <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.created_on).format('DD-MM-YYYY')}
                                      </Badge>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {item.ib_tok_note}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Pagination
                      pageCount={this.state.notescdt.lastPage || 1}
                      forcePage={this.state.notescdt.page}
                      callbackParams={{
                        search: this.state.notescdt.search,
                        limit: this.state.notescdt.rows,
                        order: this.state.notescdt.order,
                        dir: this.state.notescdt.dir,
                      }}
                      callback={this.getNotes}
                    />
                  </Tab>

                  {/* TAB PLANNING */}
                  <Tab eventKey="planning" title={t('Planning')}>
                    <Row>
                      {this.state.planningcdt.fetching && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          <Spinner as='span' animation='grow' size='sm' />{' '}
                          {t('Loading Data')}
                        </Col>
                      )}
                      {!this.state.planningcdt.fetching && this.state.planningcdt.data.length === 0 && (
                        <Col
                          md={12}
                          className='py-5 d-flex flex-column align-items-center'>
                          {t('No Data Found')}
                        </Col>
                      )}
                      {!this.state.planningcdt.fetching && this.state.planningcdt.data.length !== 0 && (
                        <Col md={12} className='pt-2'>
                          <div
                            id='datatable-list'
                            className='mb-3'
                            style={{ width: 'initial' }}>
                            <Table responsive>
                              <tbody>
                                {this.state.planningcdt.data.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ width: 85 }}>
                                      <Button variant='warning' className='me-1'
                                        onClick={() => this.editPlanning(item)}
                                        size='sm'>
                                        <FontAwesomeIcon icon={faPencil} />
                                      </Button>
                                      <Button variant='danger'
                                        onClick={() => this.deletePlanning(item)}
                                        size='sm'>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </td>
                                    <td style={{ width: 100 }}>
                                      <Badge bg="dark">
                                        <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.ib_tok_plan_date).format('DD-MM-YYYY')}
                                      </Badge>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                      {item.ib_tok_plan_text}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Pagination
                      pageCount={this.state.planningcdt.lastPage || 1}
                      forcePage={this.state.planningcdt.page}
                      callbackParams={{
                        search: this.state.planningcdt.search,
                        limit: this.state.planningcdt.rows,
                        order: this.state.planningcdt.order,
                        dir: this.state.planningcdt.dir,
                      }}
                      callback={this.getPlanning}
                    />
                  </Tab>
                </Tabs>
              </div>
            </Modal.Body>
          }
        </Modal>



        {/* DOCUMENT MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} show={this.state.showDocumentModal} onHide={this.handleCloseDocumentModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.editDocumentModal ? t('Edit') : t('Add')} {t('Document')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='formFile'>
              <Row>
                <Col>
                  <Form.Control
                    className='mb-3'
                    placeholder={t('Caption')}
                    type='text'
                    value={this.state.ib_tok_doc_caption}
                    onChange={(e) => this.setState({ ib_tok_doc_caption: e.target.value })}
                  />
                </Col>
                <Col>
                  <DatePicker
                    selected={this.state.ib_tok_doc_date}
                    onChange={(date) =>
                      this.setState({ ib_tok_doc_date: date })
                    }
                    selectsStart
                    dateFormat={'dd-MM-yyyy'}
                  />
                </Col>
              </Row>
              {!this.state.editDocumentModal &&
                <>
                  <Form.Control
                    ref={this.docRef}
                    type='file'
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      if (selectedFile.size > 10 * 1024 * 1024) {
                        toast.error(t('Allowed file size is 10MB!'));
                        this.docRef.current.value = '';
                        return;
                      }
                      this.setState({ ib_tok_doc_name: selectedFile })
                    }}
                  />
                  <div className='text-end'>
                    <small className='text-danger'>{t('Allowed file size:')} <strong>10MB</strong></small>
                  </div>
                </>
              }
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseDocumentModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.TOKDocWorking}
              variant={'success'}
              onClick={() => this.saveUpdateTOKDoc()}>
              {t('Save')}{' '}
              {this.state.TOKDocWorking && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* PRES MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} size={'xl'} show={addPresModal.show} onHide={addPresModal.toggle}>
          <Modal.Header closeButton>
            <Modal.Title>
              {addPresModal.isEditing ? t('Edit') : t('Add')} {t('Presentation')}
            </Modal.Title>
          </Modal.Header>
          {addPresModal.show &&
            <Modal.Body>
              <Row className='mb-2'>
                <Col md={8}>
                  <Form.Group >
                    <Form.Label>{t('Introduction')} <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={addPresForm.ib_tok_pres_title}
                      onChange={(e) =>
                        this.setPresValue('ib_tok_pres_title', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Date')}</Form.Label><br />
                    <DatePicker
                      selected={addTodoForm.ib_tok_pres_date}
                      onChange={(date) =>
                        this.setTodoValue('ib_tok_pres_date', date)
                      }
                      selectsStart
                      dateFormat={'dd-MM-yyyy'}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className={'mb-2'}>
                  <Form.Group >
                    <Form.Label>{t('Knowledge Question (KQ) or Scenario: Clear explanation and context')}</Form.Label>
                    <Form.Control
                      placeholder={t('Write here...')}
                      as="textarea" rows={2}
                      defaultValue={addPresForm.ib_tok_pres_know_quest}
                      onChange={(e) =>
                        this.setPresValue('ib_tok_pres_know_quest', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className={'mb-2'}>
                  <Form.Group >
                    <Form.Label>{t('Concepts: Application of key concepts (e.g., knowledge claims, perspectives, biases)')}</Form.Label>
                    <Form.Control
                      placeholder={t('Write here...')}
                      as="textarea" rows={2}
                      defaultValue={addPresForm.ib_tok_pres_concept}
                      onChange={(e) =>
                        this.setPresValue('ib_tok_pres_concept', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className={'mb-2'}>
                  <Form.Group >
                    <Form.Label>{t('Analysis and Evaluation: Critical examination of assumptions, limitations, and implications')}</Form.Label>
                    <Form.Control
                      placeholder={t('Write here...')}
                      as="textarea" rows={2}
                      defaultValue={addPresForm.ib_tok_pres_evaluation}
                      onChange={(e) =>
                        this.setPresValue('ib_tok_pres_evaluation', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className={'mb-2'}>
                  <Form.Group >
                    <Form.Label>{t('Synthesis and Application: Connections to real-life, personal experiences, or other subjects')}</Form.Label>
                    <Form.Control
                      placeholder={t('Write here...')}
                      as="textarea" rows={2}
                      defaultValue={addPresForm.ib_tok_pres_application}
                      onChange={(e) =>
                        this.setPresValue('ib_tok_pres_application', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className={'mb-2'}>
                  <Form.Group >
                    <Form.Label>{t('Counterarguments and Refutations: Addressing opposing viewpoints')}</Form.Label>
                    <Form.Control
                      placeholder={t('Write here...')}
                      as="textarea" rows={2}
                      defaultValue={addPresForm.ib_tok_pres_argument}
                      onChange={(e) =>
                        this.setPresValue('ib_tok_pres_argument', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className={'mb-2'}>
                  <Form.Group >
                    <Form.Label>{t('Conclusion (1-2 minutes): Summary, reiteration of thesis, and final thoughts')}</Form.Label>
                    <Form.Control
                      placeholder={t('Write here...')}
                      as="textarea" rows={2}
                      defaultValue={addPresForm.ib_tok_pres_conclusion}
                      onChange={(e) =>
                        this.setPresValue('ib_tok_pres_conclusion', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* {!addPresModal.isEditing && */}
              <Row>
                <Col className='d-flex flex-row'>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addPresDetail('URL')}>Add URL</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addPresDetail('Image')}>Add Image</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addPresDetail('File')}>Add File</Button>
                </Col>
              </Row>
              {/* } */}
              <Row>
                {addPresForm.details.map((v, i) => {
                  return <Col key={i} md="6">
                    <div className='border p-1 m-1'>
                      <Form.Group className='mb-3'>
                        <div className='badge badge-light-danger me-1'>{t('Presentation')}{' ' + (i + 1) + ':'}</div>
                        <Form.Label>
                          {t('Title')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          defaultValue={v.ib_tok_presd_name}
                          onChange={(e) =>
                            this.setPresDetailValue(i, 'ib_tok_presd_name', e.target.value)
                          }
                        />
                      </Form.Group>
                      {(v.ib_tok_presd_type == 'URL') &&
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('URL')}</Form.Label>
                          <Form.Control
                            type='text'
                            defaultValue={v.ib_tok_presd_value}
                            onChange={(e) =>
                              this.setPresDetailValue(i, 'ib_tok_presd_value', e.target.value)
                            }
                          />
                        </Form.Group>
                      }
                      {(v.ib_tok_presd_type == 'Image' || v.ib_tok_presd_type == 'File') &&
                        <Form.Group controlId='formFile'>
                          <Form.Label>
                            {v.ib_tok_presd_type + ' ' + t('Attachment')}
                          </Form.Label>
                          <Form.Control
                            type='file'
                            onChange={(e) => this.setPresDetailValue(i, 'ib_tok_presd_value', e.target.files[0])}
                          />
                        </Form.Group>
                      }
                      {(v.ib_tok_presd_type == 'Image' && v.ib_tok_presd_value && (typeof v.ib_tok_presd_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <img
                            alt=''
                            style={{ maxWidth: 270, height: '100%', }}
                            src={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_presentation_details/' + v.ib_tok_presd_value}
                          />
                        </div>
                      )}
                      {(v.ib_tok_presd_type == 'File' && v.ib_tok_presd_value && (typeof v.ib_tok_presd_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <a href={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_tok_presentation_details/' + v.ib_tok_presd_value} target='_blank'>Open File</a>
                        </div>
                      )}
                    </div>
                  </Col>
                })}
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant='secondary' onClick={addPresModal.toggle}>
              {t('Close')}
            </Button>
            <Button
              disabled={addPresModal.working}
              variant={addPresModal.isEditing ? 'warning' : 'success'}
              onClick={() => this.saveUpdatePres()}>
              {addPresModal.isEditing ? t('Update') : t('Save')}{' '}
              {addPresModal.working && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* NOTE MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} show={this.state.showNoteModal} onHide={this.handleCloseNoteModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.editNoteModal ? t('Edit') : t('Add')} {t('Note')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='formFile'>
              <Form.Control
                className='mb-3'
                placeholder={t('Note')}
                as="textarea" rows={3}
                value={this.state.ib_tok_note}
                onChange={(e) => this.setState({ ib_tok_note: e.target.value })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseNoteModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.TOKNoteWorking}
              variant={'success'}
              onClick={() => this.saveUpdateTOKNote()}>
              {t('Save')}{' '}
              {this.state.TOKNoteWorking && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* PLANNING MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} show={this.state.showPlanningModal} onHide={this.handleClosePlanningModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.editPlanningModal ? t('Edit') : t('Add')} {t('Plan')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={12}>
                <Form.Group controlId='formFile'>
                  <Form.Control
                    className='mb-3'
                    placeholder={t('Write here...')}
                    as="textarea" rows={3}
                    value={this.state.ib_tok_plan_text}
                    onChange={(e) => this.setState({ ib_tok_plan_text: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <DatePicker
                  selected={this.state.ib_tok_plan_date}
                  onChange={(date) =>
                    this.setState({ ib_tok_plan_date: date })
                  }
                  selectsStart
                  dateFormat={'dd-MM-yyyy'}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClosePlanningModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.TOKPlanningWorking}
              variant={'success'}
              onClick={() => this.saveUpdatetokplanning()}>
              {t('Save')}{' '}
              {this.state.TOKPlanningWorking && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>

      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedChild: state.selectedChild.data,
});

export default connect(mapStateToProps, null)(TOK);
