import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Button, Spinner, Table, Row, Col, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { t } from '../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faBook, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FrenchRose } from '../../helpers/brand_colors_helper';
const ContrastColor = require('contrast-color');

// API URL
const apiURL = process.env.REACT_APP_API_URL;

let controller;

/**
 * Dashboard Timetable
 */
class Timetable extends Component {
    // State
    state = {
        timetable: [],
        weekdays: [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
        ],
        selectedDay: moment().format('dddd'),
        selectedDate: moment().format('YYYY-MM-DD'),
    };

    /**
     * Component Did Update
     */
    componentDidMount() {
        this.getTimetable();
    }

    /**
     * Component Did Update
     */
    componentDidUpdate(prevProps, prevState) {
        const { selectedChild } = this.props;
        const { selectedDay, selectedDate } = this.state;

        if (
            prevState.selectedDay !== selectedDay ||
            prevProps.selectedChild.class_id !== selectedChild.class_id
        ) {
            this.setState({ timetable: [] });
            this.getTimetable(selectedDay, selectedDate);
        }
    }

    /**
     * Get Timetable
     * @param {string} dayname Name of the day
     * @param {string} thatDate Date (YYYY-MM-DD)
     */
    getTimetable = async (
        dayname = moment().format('dddd'),
        thatDate = moment().format('YYYY-MM-DD')
    ) => {

        let weekend_days = this.props.authData.centerData.weekend_days.split(',')
        if (weekend_days.includes(dayname.toLowerCase())) {
            console.log('ererer');
            this.setState({
                timetable: null,
                selectedDay: dayname,
            });
            return
        }

        const { authData, selectedChild } = this.props;
        const { class_id, agegroup_id, class_theme } = selectedChild;
        const { center_id } = authData.centerData;
        const { auth_key } = authData.loginData;

        const params = {
            class_id,
            center_id,
            agegroup_id,
            class_theme,
            dayname,
            thatDate,
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
        };

        if (controller) controller.abort();
        controller = new AbortController();

        try {
            const res = await axios.get(apiURL + 'dashboard/get-timetable', {
                params,
                signal: controller.signal,
            });

            this.setState({
                timetable: res.data.data.length > 0 ? res.data.data : null,
                selectedDay: dayname,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                // console.log("axiosGET request cancelled", error.message, global.baseUrl + controllerURL);
            }
            toast.error('Something went wrong while fetching timetable!');
            console.log(err);
        }
    };

    /**
     * Render Weekdays Buttons
     * @returns {HTMLElement} Outputs the buttons of weekdays
     */
    renderWeekdayButtons = () => {
        const { weekdays, selectedDay } = this.state;
        let weekend_days = this.props.authData.centerData.weekend_days.split(',')
        const weekdayButtons = weekdays.map((item, index) => {
            if (!weekend_days.includes(item.toLowerCase())) {
                return (
                    <Button
                        key={index}
                        variant={selectedDay == item ? 'primary' : 'light'}
                        className='weekday-btn'
                        onClick={() =>
                            this.handleDayDate(
                                item,
                                moment(moment().startOf('isoweek'))
                                    .add(index, 'd')
                                    .format('YYYY-MM-DD')
                            )
                        }
                        size='sm'>
                        {t(item)}
                    </Button>
                );
            }
        });
        return weekdayButtons;
    };

    /**
     * Render Timetable Time Fields
     * @returns {HTMLElement} Outputs the th of timetable table header
     */
    renderTimetableTimeFields = () => {
        const { timetable } = this.state;
        const timetableTimeFields = timetable.map((item, index) => {
            return (
                <th key={index}>
                    {moment(item.time_start, 'HH:mm:ss').format('HH:mm')} -{' '}
                    {moment(item.time_end, 'HH:mm:ss').format('HH:mm')}{' '}
                    {item.tp_type_name &&
                        <span style={{
                            borderColor: item.tp_type_color,
                            borderWidth: 1,
                            borderStyle: 'solid',
                            color: item.tp_type_color,
                            textTransform: 'uppercase',
                            borderRadius: 5,
                            paddingInline: 4,
                            paddingBlock: 2,
                            display: 'inline-block'
                        }}>
                            {item.tp_type_name}
                        </span>
                    }
                </th>
            );
        });
        return timetableTimeFields;
    };

    /**
     * Render Timetable Material Fields
     * @returns {HTMLElement} Outputs the td of timetable table body
     */
    renderTimetableSubjectFields = () => {
        // Language Direction
        const { lang_orientation: dir } = this.props.defaultLanguage;
        const { timetable } = this.state;
        const timetableTimeFields = timetable.map((item, index) => {
            const cc = new ContrastColor({
                bgColor: item.as_color,
                threshold: 200,
            });

            const defaultFgColor = cc.contrastColor();

            return (
                <td
                    key={index}
                    style={{
                        backgroundColor: '#fff',
                    }}>
                    {item.acs_name ? (
                        <div className='d-flex flex-direction-row justify-content-center'>
                            {item.activeLessons.length > 0 ? (
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id={`lesson-${item.acs_id}`}
                                        size='sm'
                                        style={{
                                            backgroundColor:
                                                item.as_color,
                                            borderColor: item.as_color,
                                            color: defaultFgColor,
                                        }}>
                                        {item.acs_name}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {item.activeLessons.map(
                                            (lesson_item, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    as='div'
                                                    style={{
                                                        fontSize: '14px',
                                                    }}>
                                                    <Link
                                                        to={'/lesson-details/' + lesson_item.asl_id
                                                        }
                                                        className='dropdown-item'>
                                                        {lesson_item.asl_name}
                                                    </Link>
                                                </Dropdown.Item>
                                            )
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : (
                                <Button
                                    disabled={true}
                                    variant='unknown'
                                    style={{ backgroundColor: item.as_color, borderColor: item.as_color, color: defaultFgColor }}
                                    size='sm'>
                                    {item.acs_name}
                                </Button>
                            )}
                            <>
                                {item.class_online_url !== null && (
                                    <Button
                                        className={dir == 1 ? 'me-2' : 'ms-2'}
                                        variant='tertiary'
                                        size='sm'
                                        title={t('Online CLass')}
                                        onClick={() => window.open(item.class_online_url, '_blank', 'noopener,noreferrer')}>
                                        <FontAwesomeIcon
                                            icon={faGlobe}
                                            color='#ffffff'
                                        />
                                    </Button>
                                )}
                            </>
                        </div>
                    ) : (
                        <Button disabled={true} variant='dark' size='sm'>
                            {t('FREE')}
                        </Button>
                    )}
                </td>
            );
        });
        return timetableTimeFields;
    };

    /**
     * Handle Day & Date Selection
     * @param {string} selectedDay Day name of the week
     * @param {string} selectedDate Date (YYYY-MM-DD)
     */
    handleDayDate = (selectedDay, selectedDate) => {
        this.setState({
            selectedDay,
            selectedDate,
        });
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        // State
        const { timetable } = this.state;

        return (
            <div
                style={{ padding: 5, borderRadius: 20 }}
                className='dashboard-timetable grey-section'>
                <div className='weekday-btns'>
                    {this.renderWeekdayButtons()}
                    <Link to='/timetable' className='weekday-btn'>
                        <Button
                            variant='warning'
                            size='sm'
                            style={{
                                borderTopRightRadius: 15,
                                width: '100%'
                            }}>
                            {t('Full Week') + ' '}
                            <FontAwesomeIcon
                                icon={faArrowUpRightFromSquare}
                            />
                        </Button>
                    </Link>
                </div>
                {timetable !== null && timetable.length > 0 ? (
                    <Table className='dashboard-timetable-table text-center'>
                        <thead>
                            <tr>{this.renderTimetableTimeFields()}</tr>
                        </thead>
                        <tbody>
                            <tr>{this.renderTimetableSubjectFields()}</tr>
                        </tbody>
                    </Table>
                ) : (
                    <div
                        style={{
                            height: '89px',
                            flexDirection: 'column',
                        }}
                        className='d-flex justify-content-center align-items-center'>
                        {timetable !== null ? (
                            <>
                                <span className='mb-1'>
                                    <Spinner animation='grow' variant='dark' />
                                </span>
                                {t('Fetching timetable...')}
                            </>
                        ) : (
                            <> {t('No timetable found!')}</>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(Timetable);
