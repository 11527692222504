import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "./translation_helper";

export const renderTableHead = (_this) => {
  const cdt = _this.state.cdt
  return <thead>
    <tr>
      {cdt.columns.filter((column) => column.visibility).map((column, column_index) => {
        let width = column.width ? column.width : 90;
        //maxWidth: width, minWidth: width
        return (
          <th key={column_index} style={{ minWidth: width }}>
            <div className='d-flex flex-row justify-content-between align-items-center cursor-pointer'
              onClick={() => column.sortable ? _this.fetch({
                search: cdt.search,
                limit: (cdt.rows || cdt.limit),
                order: column.id,
                dir: (cdt.order == column.id) ? cdt.dir == 'ASC' ? 'DESC' : 'ASC' : 'DESC',
                page: cdt.page
              }) : null}
            >
              <div style={{ textWrap: 'nowrap' }}>{t(column.name)}</div>
              {column.sortable &&
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: 28, fontSize: 14, }}>
                  <FontAwesomeIcon icon={faSortUp} style={{ color: cdt.order == column.id && cdt.dir == 'DESC' ? '#000000' : '#cccccc' }} />
                  <FontAwesomeIcon icon={faSortDown} style={{ color: cdt.order == column.id && cdt.dir == 'ASC' ? '#000000' : '#cccccc' }} />
                </div>
              }
            </div>
          </th>
        )
      })}
    </tr>
  </thead>
}

export const renderTableBody = (_this) => {
  const cdt = _this.state.cdt
  return <tbody>
    {cdt.data.map((item, index) => (
      <tr key={index}>
        {cdt.columns
          .filter((column) => column.visibility)
          .map((column, column_index) => _this.renderCell(item[column.column], column.column, column_index, item, index))}
      </tr>
    ))}
  </tbody>
}