import { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

class Pagination extends Component {
  render() {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <ReactPaginate
          className='pagination'
          previousLinkClassName='btn btn-light me-1'
          nextLinkClassName='btn btn-light ms-1'
          pageLinkClassName='btn btn-light mx-1'
          breakLinkClassName='btn btn-light'
          activeLinkClassName='btn btn-primary'
          breakLabel={<FontAwesomeIcon icon={faEllipsis} color={'#000'} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} color={'#000'} />}
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} color={'#000'} />}
          onPageChange={(e) => this.props.callback({ ...this.props.callbackParams, page: e.selected })}
          pageRangeDisplayed={5}
          pageCount={this.props.pageCount}
          renderOnZeroPageCount={null}
          forcePage={this.props.forcePage}
        />
      </div>
    );
  }
}

export default Pagination;