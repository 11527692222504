import React, { Component } from 'react';
import { Container, Card, Row, Col, Badge, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import DataTable from 'react-data-table-component';
import './analysis.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { VividMalachite } from '../../helpers/brand_colors_helper';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// API URL
const apiURL = process.env.REACT_APP_API_URL;

/**
 * Analysis Component
 */
class Analysis extends Component {
  // State
  state = {
    achievement: null,
    charts: null,
    backgroundColor: [
      '#0CBBE7',
      '#00D045',
      '#EE3A81',
      '#FCEC6B',
      '#C75FC9',
      '#3C63AE',
      '#FC876B',
      '#6A9C06',
      '#E33030',
    ],
    points__data: [],
    points__loading: false,
    points__totalRows: 0,
    points__perPage: 10,
    examResults__data: [],
    examResults__loading: false,
    examResults__totalRows: 0,
    examResults__perPage: 10,
    subjectsAnalytics: null,
  };

  componentDidMount() {
    this.getAchievement();
    this.getCharts();
    this.getPoints(1);
    this.getExamResults(1);
    this.getSubjectsAnalytics();
  }

  /**
   * Get Achievement Data
   */
  getAchievement = async () => {
    const { auth_key, center_id } = this.props.authData.loginData;
    const { child_id, class_id } = this.props.selectedChild;

    let request_params = {
      center_id,
      class_id,
      child_id,
      auth_key,
    };

    try {
      const req = await axios.get(
        apiURL + 'dashboard/get-student-points',
        {
          params: request_params,
        }
      );

      console.log('req', req);

      this.setState({
        achievement: req.data.data,
      });
    } catch (err) {
      toast.error('Something went wrong while fetching achievement!');
      console.log(err);
    }
  };

  /**
   * Get Charts Data
   */
  getCharts = async () => {
    const { backgroundColor } = this.state;
    const { auth_key, center_id } = this.props.authData.loginData;
    const { child_id, class_id, classmain_id } = this.props.selectedChild;

    let request_params = {
      center_id,
      class_id,
      child_id,
      auth_key,
      classmain_id,
    };

    try {
      const req = await axios.get(
        apiURL + 'dashboard/chart-class-average',
        {
          params: request_params,
        }
      );

      const chart_data = req.data.data;

      this.setState({
        charts: {
          yourAverage: {
            labels: chart_data.all_classmaterials,
            datasets: [
              {
                data: chart_data.yourAverage,
                backgroundColor,
              },
            ],
          },
          classAverage: {
            labels: chart_data.all_classmaterials,
            datasets: [
              {
                data: chart_data.classAverage,
                backgroundColor,
              },
            ],
          },
          maxAverage: {
            labels: chart_data.all_classmaterials,
            datasets: [
              {
                data: chart_data.maxAverage,
                backgroundColor,
              },
            ],
          },
          minAverage: {
            labels: chart_data.all_classmaterials,
            datasets: [
              {
                data: chart_data.minAverage,
                backgroundColor,
              },
            ],
          },
        },
      });
    } catch (err) {
      toast.error('Something went wrong while fetching achievement!');
      console.log(err);
    }
  };

  /**
   * Get Points Data
   */
  getPoints = async (page) => {
    this.setState({
      points__loading: true,
    });

    const { perPage__total } = this.state;
    const { auth_key, center_id } = this.props.authData.loginData;
    const { child_id } = this.props.selectedChild;

    let request_params = {
      center_id,
      child_id,
      page,
      offset: (page - 1) * 10,
      per_page: perPage__total,
      order: 'std_behaviour_id',
      auth_key,
    };

    try {
      const req = await axios.get(apiURL + 'dashboard/table-points', {
        params: request_params,
      });

      console.log('req', req);

      this.setState({
        points__data: req.data.data,
        points__totalRows: req.data.total,
        points__loading: false,
      });
    } catch (err) {
      toast.error('Something went wrong while fetching points!');
      console.log(err);
    }
  };

  handlePointsPageChange = (page) => {
    this.getPoints(page);
  };

  /**
   * Get Exam Results Data
   */
  getExamResults = async (page) => {
    this.setState({
      points__loading: true,
    });

    const { perPage__total } = this.state;
    const { auth_key, center_id } = this.props.authData.loginData;
    const { child_id, class_id } = this.props.selectedChild;

    let request_params = {
      center_id,
      child_id,
      class_id,
      page,
      offset: (page - 1) * 10,
      per_page: perPage__total,
      order: 'std_behaviour_id',
      auth_key,
    };

    try {
      const res = await axios.get(
        apiURL + 'dashboard/table-exam-results',
        {
          params: request_params,
        }
      );

      console.log('req', res);

      this.setState({
        examResults__data: res.data.data,
        examResults__totalRows: res.data.total,
        examResults__loading: false,
      });
    } catch (err) {
      toast.error('Something went wrong while fetching points!');
      console.log(err);
    }
  };

  /**
   * Handle Exam Results Page Change Event
   * @param {number} page
   */
  handleExamResultsPageChange = (page) => {
    this.getExamResults(page);
  };

  /**
   * Get Subjects Analytics
   */
  getSubjectsAnalytics = async () => {


    this.setState({ subjectsAnalytics: null });
    const { authData, selectedChild } = this.props;
    const { class_id, agegroup_id, child_id } = selectedChild;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    const { backgroundColor } = this.state;

    const params = {
      class_id,
      child_id,
      center_id,
      agegroup_id,
      auth_key: auth_key,
    };

    try {
      const res = await axios.get(
        apiURL + 'dashboard/chart-class-subject-average',
        {
          params,
        }
      );

      const barChartData = [];

      res.data.data.map((item) => {
        const bar_data = {
          labels: [
            t('Your Average'),
            t('Class Average'),
            t('Max Average'),
            t('Min Average'),
          ],
          datasets: [
            {
              label: item.material_name,
              data: [
                item.yourAverage,
                item.classAverage,
                item.maxAverage,
                item.minAverage,
              ],
              backgroundColor,
              borderRadius: 20,
              barThickness: 40,
              borderSkipped: false,
            },
          ],
        };

        barChartData.push({
          material_name: item.material_name,
          bar_data,
        });
      });

      this.setState({
        subjectsAnalytics: barChartData,
      });
    } catch (err) {
      this.setState({
        subjectsAnalytics: [],
      });
      toast.error(
        'Something went wrong while fetching subjects analytics!'
      );
      console.log(err);
    }
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // State
    const {
      achievement,
      charts,
      points__data,
      points_loading,
      points__totalRows,
      examResults__loading,
      examResults__data,
      examResults__totalRows,
      subjectsAnalytics,
    } = this.state;

    // Achievement style 💅 class
    let achievement_class = 'border-0 achievement';
    if (achievement !== null && achievement.lastAward) {
      achievement_class = 'border-0 achievement trophy';
    }
    if (achievement !== null && !achievement.lastAward) {
      achievement_class = 'border-0 achievement thumbs-up';
    }

    // Chart options
    const options = {
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    const bar_options = {
      // responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
    };

    // Points DT columns
    const points__columns = [
      {
        name: 'Date',
        selector: (row) => row.date,
      },
      {
        name: 'Award',
        selector: (row) => row.award_name,
      },
      {
        name: 'Points',
        selector: (row) => row.points,
      },
      {
        name: 'Comments',
        selector: (row) => row.behaviour,
      },
      {
        name: 'From',
        selector: (row) => row.employee_name,
      },
    ];

    // Exam Results DT columns
    const examResults__columns = [
      {
        name: 'Exam',
        selector: (row) => row.exams_name,
      },
      {
        name: 'Subject',
        selector: (row) => row.classmaterial_name,
      },
      {
        name: 'Date',
        selector: (row) => row.exams_date,
      },
      {
        name: 'Total Marks',
        selector: (row) => row.examsgrade_totalgrade,
      },
      {
        name: 'Highest',
        selector: (row) => row.qmax,
      },
      {
        name: 'Lowest',
        selector: (row) => row.qmin,
      },
    ];

    return (
      <Container fluid id='analysis-page'>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Analysis')} />
            <div className='grey-section'>
              <Row>
                <Col>
                  <Card className={achievement_class}>
                    <Card.Body>
                      {achievement == null ? (
                        <div
                          style={{
                            height: '200px',
                            flexDirection: 'column',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          <span className='mb-1'>
                            <Spinner
                              animation='grow'
                              variant='dark'
                            />
                          </span>
                          {t(
                            'Fetching achievement...'
                          )}
                        </div>
                      ) : (
                        <>
                          {achievement.lastAward ? (
                            <>
                              {' '}
                              <h3>
                                {t(
                                  'Congratulations!'
                                )}
                              </h3>
                              <p>
                                {t(
                                  'You got a Award'
                                )}
                              </p>
                              <p>
                                <strong>
                                  {t(
                                    'Encouragement'
                                  )}
                                </strong>
                                <br />
                                <span
                                  className='badge'
                                  style={{
                                    backgroundColor:
                                      '#00D045',
                                  }}>
                                  15{' '}
                                  {t(
                                    'Points'
                                  )}
                                </span>
                              </p>
                            </>
                          ) : (
                            <>
                              <h3>
                                {t(
                                  'Keep it up!'
                                )}
                              </h3>
                              <p>
                                {t(
                                  'You are doing good.'
                                )}
                              </p>
                              <p>
                                <strong>
                                  {t(
                                    'Study hard to get'
                                  )}
                                  <br />
                                  {t(
                                    'more points.'
                                  )}
                                </strong>
                              </p>
                            </>
                          )}
                          <p>
                            {t('Total Points')}
                            <br />
                            <strong
                              style={{
                                fontSize:
                                  '25px',
                              }}>
                              {
                                achievement.totalPoints
                              }
                            </strong>
                          </p>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className='border-0 graph-card'>
                    <Card.Body>
                      <Card.Subtitle className='mb-4'>
                        {t('Your Average')}
                      </Card.Subtitle>
                      {charts !== null ? (
                        <Doughnut
                          data={charts.yourAverage}
                          options={options}
                        />
                      ) : (
                        <div
                          style={{
                            height: '175px',
                            flexDirection: 'column',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          <span className='mb-1'>
                            <Spinner
                              animation='grow'
                              variant='dark'
                            />
                          </span>
                          {t('Fetching average...')}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className='border-0 graph-card'>
                    <Card.Body>
                      <Card.Subtitle className='mb-4'>
                        {t('Class Average')}
                      </Card.Subtitle>
                      {charts !== null ? (
                        <Doughnut
                          data={charts.classAverage}
                          options={options}
                        />
                      ) : (
                        <div
                          style={{
                            height: '175px',
                            flexDirection: 'column',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          <span className='mb-1'>
                            <Spinner
                              animation='grow'
                              variant='dark'
                            />
                          </span>
                          {t('Fetching average...')}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className='border-0 graph-card'>
                    <Card.Body>
                      <Card.Subtitle className='mb-4'>
                        {t('Highest Average')}
                      </Card.Subtitle>
                      {charts !== null ? (
                        <Doughnut
                          data={charts.maxAverage}
                          options={options}
                        />
                      ) : (
                        <div
                          style={{
                            height: '175px',
                            flexDirection: 'column',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          <span className='mb-1'>
                            <Spinner
                              animation='grow'
                              variant='dark'
                            />
                          </span>
                          {t('Fetching average...')}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={2}>
                  <Card className='border-0 graph-card last-graph-card'>
                    <Card.Body>
                      <Card.Subtitle className='mb-4'>
                        {t('Lowest Average')}
                      </Card.Subtitle>
                      {charts !== null ? (
                        <Doughnut
                          data={charts.minAverage}
                          options={options}
                          width={40}
                          height={40}
                        />
                      ) : (
                        <div
                          style={{
                            height: '175px',
                            flexDirection: 'column',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          <span className='mb-1'>
                            <Spinner
                              animation='grow'
                              variant='dark'
                            />
                          </span>
                          {t('Fetching average...')}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {subjectsAnalytics == null &&
                <Row
                  style={{
                    paddingTop: 20,
                    marginBottom: 20,
                  }}>
                  <Col>
                    <Card className='border-0'>
                      <Card.Body>
                        <div
                          style={{
                            height: '175px',
                            flexDirection: 'column',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          <span className='mb-1'>
                            <Spinner
                              animation='grow'
                              variant='dark'
                            />
                          </span>
                          {t(
                            'Fetching subjects analytics...'
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              }
              {subjectsAnalytics?.length == 0 && (
                <Row
                  style={{
                    paddingTop: 20,
                    marginBottom: 20,
                  }}>
                  <Col>
                    <Card className='border-0'>
                      <Card.Body>
                        <div
                          style={{
                            height: '175px',
                            flexDirection: 'column',
                          }}
                          className='d-flex justify-content-center align-items-center'>
                          {t(
                            'No subjects analytics found!'
                          )}
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
              {subjectsAnalytics?.length > 0 && (
                <Row style={{ paddingTop: 20 }}>
                  {subjectsAnalytics.map((item, index) => (
                    <Col
                      key={index}
                      md={4}
                      style={{ marginBottom: 20 }}>
                      <Card className='border-0'>
                        <Card.Body>
                          <Card.Title className='mb-2'>
                            {t(item.material_name)}{' '}
                            {t('Analysis')}
                          </Card.Title>
                          <Bar
                            options={bar_options}
                            data={item.bar_data}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
              <Row>
                <Col>
                  <Card
                    className='border-0'
                    style={{ borderRadius: 10 }}>
                    <Card.Body>
                      <Card.Title>
                        {t('Points')}
                      </Card.Title>
                      <DataTable
                        columns={points__columns}
                        progressPending={points_loading}
                        data={points__data}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          points__totalRows
                        }
                        onChangePage={
                          this.handlePointsPageChange
                        }
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row style={{ paddingTop: 20 }}>
                <Col>
                  <Card
                    className='border-0'
                    style={{
                      borderRadius: 10,
                      borderBottomRightRadius: 20,
                      borderBottomLeftRadius: 20,
                    }}>
                    <Card.Body>
                      <Card.Title>
                        {t('Exam Results')}
                      </Card.Title>
                      <DataTable
                        columns={examResults__columns}
                        progressPending={
                          examResults__loading
                        }
                        data={examResults__data}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          examResults__totalRows
                        }
                        onChangePage={
                          this
                            .handleExamResultsPageChange
                        }
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(Analysis);
