import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faCheckCircle, faChevronDown, faChevronUp, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faPlus, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import imgDescription from '../../assets/images/description.png';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
// import { DateRangePicker } from 'react-date-range';
import { Black, ChineseSilver, Gray, SunsetOrange, VividMalachite, White, AliceBlue, OrangeRYB } from '../../helpers/brand_colors_helper';
import { Scrollbar } from 'react-scrollbars-custom';

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

class Appointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeData: [],
            selectedEmployee: null,
            appointmentsData: [],
            isRefreshing: false,
            appointmentModal: {
                show: false,
                toggleModal: (data = null) => this.setState({
                    appointmentModal: { ...this.state.appointmentModal, show: !this.state.appointmentModal.show, data },
                }),
                saving: false
            },
            dateRangePicker: {
                show: false,
                togglePicker: () => this.setState({ dateRangePicker: { ...this.state.dateRangePicker, show: !this.state.dateRangePicker.show } }),
                value: {
                    startDate: new Date(), endDate: new Date(), key: 'selection'
                }
            },
            fData: {
                appointment_booking_description: ''
            }
            // appointment_slot_from_time":"00:00","appointment_slot_to_time":"00:30"
        }

    }

    componentDidMount() {
        this.getEmployees()
    }


    getEmployees = async () => {
        const { auth_key, center_id } = this.props.authData.loginData;
        const { child_id, class_id } = this.props.selectedChild;

        let request_params = {
            center_id,
            class_id,
            auth_key,
            appname: process.env.REACT_APP_NAME,
        };

        if (this.getEmployeesReq) this.getEmployeesReq.abort();
        this.getEmployeesReq = new AbortController();

        try {
            const req = await axios.get(
                apiURL + 'appointment-booking/get-employees',
                {
                    params: request_params,
                    signal: this.getEmployeesReq.signal,
                }
            );

            console.log('getEmployees', req);

            this.setState({
                employeeData: req.data,
            });
        } catch (err) {
            toast.error('Something went wrong while fetching data!');
            console.log(err);
        }
    };

    calendarData = async (startdate = this.startdate, enddate = this.enddate) => {
        this.startdate = startdate
        this.enddate = enddate

        if (this.state.selectedEmployee == null) {
            toast.warning(t('Please choose addressee!'));
            return
        }

        this.setState({ isRefreshing: true })

        const { authData } = this.props;
        const { auth_key, user_id, role_id } = authData.loginData;
        const { employee_id } = this.state.selectedEmployee;
        const { center_id, center_timezone } = authData.centerData;
        const { parent_id } = this.props.selectedChild;

        if (this.calendarDataReq) this.calendarDataReq.abort();
        this.calendarDataReq = new AbortController();
        try {
            const res = await axios.get(apiURL + 'appointment-booking/calendar-data', {
                params: {
                    auth_key: auth_key,
                    appname: process.env.REACT_APP_NAME,
                    startdate: moment(startdate).format('YYYY-MM-DD'),
                    enddate: moment(enddate).format('YYYY-MM-DD'),
                    center_id,
                    employee_id,
                    parent_id,
                    center_timezone
                },
                signal: this.calendarDataReq.signal,
            });

            this.setState({
                appointmentsData: res.data.map((v) => {
                    let backgroundColor = VividMalachite
                    if (v.state == 'booked') {
                        backgroundColor = SunsetOrange
                    } else if (v.state == 'canceled') {
                        backgroundColor = OrangeRYB
                    }
                    return { ...v, groupId: v.raw.appointments.appointment_id, start: v.start_datetime, end: v.end_datetime, title: (v.state).toUpperCase(), backgroundColor: backgroundColor }
                }),
                isRefreshing: false
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                // console.log("axiosGET request cancelled", error.message, global.baseUrl + controllerURL);
            } else {
                toast.error(t('Something went wrong while fetching data!'));
                console.log(err);
            }

        }
    };

    setStateFData = (key, value) => {
        this.setState({
            fData: { ...this.state.fData, [key]: value },
        })
    }

    bookAppointment = () => {
        let appointmentModal = this.state.appointmentModal
        if (appointmentModal.saving) {
            return
        }
        this.setState({ appointmentModal: { ...appointmentModal, saving: true } }, async () => {
            const { authData } = this.props;
            const { center_id, center_timezone } = authData.centerData;
            const { auth_key } = authData.loginData;
            const { employee_id } = this.state.selectedEmployee;
            const { parent_id } = this.props.selectedChild;

            const pData = new FormData();
            pData.append('center_id', center_id)
            pData.append('center_timezone', center_timezone)
            pData.append('appointment_booking_description', this.state.fData.appointment_booking_description)
            pData.append('appointment_id', appointmentModal.data.raw.appointments.appointment_id)
            pData.append('appointment_slot_id', appointmentModal.data.raw.appointment_slots
                .appointment_slot_id)
            pData.append('appointment_booked_employee_id', employee_id)
            pData.append('appointment_booked_date', moment(appointmentModal.data.start_datetime).format('YYYY-MM-DD'))
            pData.append('appointment_booked_from_time', appointmentModal.data.start_datetime)
            pData.append('appointment_booked_to_time', appointmentModal.data.end_datetime)
            pData.append('appointment_booking_status', 'booked')
            pData.append('parent_id', parent_id)

            if (this.bookAppointmentReq) this.bookAppointmentReq.abort();
            this.bookAppointmentReq = new AbortController();

            try {
                const res = await axios.post(
                    apiURL + 'appointment-booking/book-appointment',
                    pData,
                    {
                        params: {
                            auth_key: auth_key,
                            appname: process.env.REACT_APP_NAME,
                        },
                        signal: this.bookAppointmentReq.signal,
                    }
                );

                console.log('res', res);
                if (res) {
                    let resData = res.data
                    if (resData.type) {
                        toast[resData.messageType](t(resData.message));
                        this.setState({
                            appointmentModal: { ...appointmentModal, saving: false, show: false, data: null, },
                            fData: {
                                appointment_booking_description: ''
                            }
                        }, () => {
                            this.calendarData()
                        })
                    } else {
                        if (resData.data == null) {
                            toast[resData.messageType](t(resData.message));
                        } else {
                            handleFormErrors(resData.data)
                        }
                        this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                    }
                } else {
                    toast.error(t('Something went wrong while saving!'));
                    this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    // console.log("axiosGET request cancelled", error.message, global.baseUrl + controllerURL);
                } else {
                    toast.error(t('Something went wrong while saving!'));
                    console.log(err);
                    this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                }
            }
        })
    }
    cancelAppointment = () => {
        let appointmentModal = this.state.appointmentModal
        if (appointmentModal.saving) {
            return
        }
        this.setState({ appointmentModal: { ...appointmentModal, saving: true } }, async () => {
            const { authData } = this.props;
            const { center_id, center_timezone } = authData.centerData;
            const { auth_key } = authData.loginData;
            const { employee_id } = this.state.selectedEmployee;
            const { parent_id } = this.props.selectedChild;

            const pData = new FormData();
            pData.append('center_id', center_id)
            pData.append('center_timezone', center_timezone)
            pData.append('appointment_booking_id', appointmentModal.data.raw.appointment_bookings.appointment_booking_id)

            if (this.cancelAppointmentReq) this.cancelAppointmentReq.abort();
            this.cancelAppointmentReq = new AbortController();

            try {
                const res = await axios.post(
                    apiURL + 'appointment-booking/cancel-appointment',
                    pData,
                    {
                        params: {
                            auth_key: auth_key,
                            appname: process.env.REACT_APP_NAME,
                        },
                        signal: this.cancelAppointmentReq.signal,
                    }
                );

                console.log('res', res);
                if (res) {
                    let resData = res.data
                    if (resData.type) {
                        toast[resData.messageType](t(resData.message));
                        this.setState({
                            appointmentModal: { ...appointmentModal, saving: false, show: false, data: null, },
                            fData: {
                                appointment_booking_description: ''
                            }
                        }, () => {
                            this.calendarData()
                        })
                    } else {
                        if (resData.data == null) {
                            toast[resData.messageType](t(resData.message));
                        } else {
                            handleFormErrors(resData.data)
                        }
                        this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                    }
                } else {
                    toast.error(t('Something went wrong while saving!'));
                    this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    // console.log("axiosGET request cancelled", error.message, global.baseUrl + controllerURL);
                } else {
                    toast.error(t('Something went wrong while saving!'));
                    console.log(err);
                    this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                }
            }
        })
    }

    bookAgainAppointment = () => {
        let appointmentModal = this.state.appointmentModal
        if (appointmentModal.saving) {
            return
        }
        this.setState({ appointmentModal: { ...appointmentModal, saving: true } }, async () => {
            const { authData } = this.props;
            const { center_id, center_timezone } = authData.centerData;
            const { auth_key } = authData.loginData;
            const { employee_id } = this.state.selectedEmployee;
            const { parent_id } = this.props.selectedChild;

            const pData = new FormData();
            pData.append('center_id', center_id)
            pData.append('center_timezone', center_timezone)
            pData.append('appointment_booking_id', appointmentModal.data.raw.appointment_bookings.appointment_booking_id)

            if (this.cancelAppointmentReq) this.cancelAppointmentReq.abort();
            this.cancelAppointmentReq = new AbortController();

            try {
                const res = await axios.post(
                    apiURL + 'appointment-booking/book-again-appointment',
                    pData,
                    {
                        params: {
                            auth_key: auth_key,
                            appname: process.env.REACT_APP_NAME,
                        },
                        signal: this.cancelAppointmentReq.signal,
                    }
                );

                console.log('res', res);
                if (res) {
                    let resData = res.data
                    if (resData.type) {
                        toast[resData.messageType](t(resData.message));
                        this.setState({
                            appointmentModal: { ...appointmentModal, saving: false, show: false, data: null, },
                            fData: {
                                appointment_booking_description: ''
                            }
                        }, () => {
                            this.calendarData()
                        })
                    } else {
                        if (resData.data == null) {
                            toast[resData.messageType](t(resData.message));
                        } else {
                            handleFormErrors(resData.data)
                        }
                        this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                    }
                } else {
                    toast.error(t('Something went wrong while saving!'));
                    this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                    // console.log("axiosGET request cancelled", error.message, global.baseUrl + controllerURL);
                } else {
                    toast.error(t('Something went wrong while saving!'));
                    console.log(err);
                    this.setState({ appointmentModal: { ...appointmentModal, saving: false } })
                }
            }
        })
    }

    eventClick = (eventClickInfo) => {
        let eventData = eventClickInfo.event.extendedProps
        console.log('eventData', eventData);
        this.state.appointmentModal.toggleModal(eventData)
    }
    render() {
        const { appointmentsData, appointmentModal, fData, employeeData, selectedEmployee } = this.state
        const { appointment_slot_length, appointment_slot_intervals, generateSlotsData } = fData
        const { lang_orientation: dir, lang_flag } = this.props.defaultLanguage;
        console.log('APPOINTMENT', this.state);
        return (<Container fluid>
            <div id='iedu-layout'>
                <NavBar />
                <div id='page-content'>
                    <Header lite={true} heading={t('Appointments')} backBtn={true} func={[this.getEmployees]} showChildSelector={true} />
                    <div className='grey-section' style={{ padding: '10px' }}>
                        <Scrollbar style={{ width: '100%', height: 120 }} className='d-flex flex-row align-items-center' noScrollY={true} permanentTrackX={true} >
                            <div className='d-flex flex-row align-items-center' style={{ height: 100, width: (employeeData.length * 150) + 'px' }}>
                                {employeeData.map((v, i) => {
                                    let selected = false
                                    if (selectedEmployee && selectedEmployee.employee_id == v.employee_id) {
                                        selected = true
                                    }
                                    return <div key={i} style={{ width: '150px', cursor: 'pointer' }} className='d-flex flex-column align-items-center justify-content-center' onClick={() => this.setState({ selectedEmployee: v }, () => this.calendarData())}>
                                        <div style={{ position: 'relative' }}>
                                            <Image src={portalURL + this.props.authData.centerData.center_uuid + '/employees/' + v.employee_picture} roundedCircle style={{ height: '50px', width: '50px', aspectRatio: 1, objectFit: 'cover' }} alt='Picture' />
                                            {selected &&
                                                <div style={{ position: 'absolute', top: -5, right: -5 }}>
                                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: VividMalachite, fontSize: 20 }} />
                                                </div>
                                            }

                                        </div>
                                        <div style={{ fontSize: 14, fontWeight: 'bold', color: Black, opacity: 0.7 }}>{v.employee_name}</div>
                                        <div style={{ fontSize: 12, fontWeight: 'bold', color: Black, opacity: 0.6 }}>{v.role_name}</div>
                                    </div>
                                })}
                            </div>
                        </Scrollbar>
                    </div>
                    <div className='grey-section'>
                        {this.state.isRefreshing &&
                            <div className='d-flex align-items-center justify-content-center'>
                                <Spinner animation='grow' variant='dark' />
                            </div>
                        }
                        <FullCalendar
                            height={'auto'}
                            headerToolbar={{
                                left: "today prev,next",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                            }}
                            plugins={[
                                dayGridPlugin,
                                interactionPlugin,
                                timeGridPlugin,
                                listPlugin
                            ]}
                            initialView='timeGridWeek'
                            events={appointmentsData}
                            eventClick={this.eventClick}
                            locale={dir == 1 ? 'ar-kw' : 'en'}
                            direction={dir == 1 ? 'rtl' : 'ltr'}
                            datesSet={(dateInfo) => { //INVOKE WHEN DATE SET CHANGED
                                let dateStart = dateInfo.start
                                let dateEnd = dateInfo.end
                                this.calendarData(dateStart, dateEnd)
                            }}
                        />
                    </div>
                </div>
            </div>

            <Modal show={appointmentModal.show} onHide={appointmentModal.toggleModal}>
                {appointmentModal.show &&
                    <Modal.Header closeButton>
                        {appointmentModal.data.raw.appointment_bookings.appointment_booking_id
                            ? <Modal.Title>{t('Appointment Details')}</Modal.Title>
                            : <Modal.Title>{t('Book Appointment')}</Modal.Title>
                        }
                    </Modal.Header>
                }
                {appointmentModal.show &&
                    <Modal.Body>
                        {appointmentModal.data.raw.appointment_bookings.appointment_booking_id
                            ? <div style={{}}>
                                <div style={{ padding: '1.5rem' }}>
                                    <Row>
                                        <Col>
                                            <div className='d-flex flex-row flex-wrap'>
                                                {/*CANCELED */}
                                                {appointmentModal.data.raw.appointment_bookings.appointment_booking_status == "canceled" && t("You have canceled the appointment on")}
                                                {/*BOOKED */}
                                                {appointmentModal.data.raw.appointment_bookings.appointment_booking_status == "booked" && t("You have booked for appointment on")}
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{moment(appointmentModal.data.start_datetime).format('DD-MM-YYYY')}</span>
                                                {t("from")}
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{moment(appointmentModal.data.start_datetime).format('HH:mm')}</span>
                                                {t("to")}
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{moment(appointmentModal.data.end_datetime).format('HH:mm')}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            : <div style={{}}>
                                <div style={{ padding: '1.5rem' }}>
                                    <Row>
                                        <Col>
                                            <div className='d-flex flex-row flex-wrap'>
                                                {t("You are going to book appointment on")}
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{moment(appointmentModal.data.start_datetime).format('DD-MM-YYYY')}</span>
                                                {t("from")}
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{moment(appointmentModal.data.start_datetime).format('HH:mm')}</span>
                                                {t("to")}
                                                <span style={{ fontWeight: 'bold', margin: '0 10px' }}>{moment(appointmentModal.data.end_datetime).format('HH:mm')}</span>
                                                {t("Please write some message to let the person know about this appointment.")}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: 10 }}>
                                        <Col>
                                            <Form.Group>
                                                <Form.Control as="textarea" rows={3} onChange={(event) => this.setStateFData('appointment_booking_description', event.target.value)} placeholder={t('Message')} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </div>
                            </div>
                        }
                    </Modal.Body>
                }
                {appointmentModal.show &&
                    <Modal.Footer>
                        {appointmentModal.data.raw.appointment_bookings
                            ? (appointmentModal.data.raw.appointment_bookings.appointment_booking_status == "canceled")
                                // CANCELED
                                ? <Button variant='danger' onClick={() => this.bookAgainAppointment()}>{t('Book again this Appointment')}</Button>
                                // BOOKED
                                : <Button variant='danger' onClick={() => this.cancelAppointment()}>{t('Cancel this Appointment')}</Button>
                            : <Button variant='success' onClick={() => this.bookAppointment()}>{t('Confirm Booking')}</Button>
                        }
                    </Modal.Footer>
                }
            </Modal>
        </Container >);
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(Appointments);