import React, { Component } from 'react';
import { Container, Card, Row, Col, Spinner, Button } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { withParams } from '../../helpers/params_helper';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import GeneralCard from '../../helpers/GeneralCard';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Download Media Component
 */
class DownloadMedia extends Component {
    // States
    state = {
        albums: null,
    };

    componentDidMount() {
        this.getAlbums();
    }

    /**
     * Get Albums
     */
    getAlbums = async () => {
        const { authData } = this.props;
        const { auth_key } = authData.loginData;
        const { center_id } = authData.centerData;
        const { selectedChild } = this.props;
        let request_params = {
            center_id,
            class_id: selectedChild.class_id,
            child_id: selectedChild.child_id,
            auth_key: auth_key,
        };

        try {
            const res = await axios.get(apiURL + 'user-timeline/get-albums', {
                params: request_params,
            });

            this.setState({
                albums: res.data,
            });
        } catch (err) {
            toast.error('Something went wrong while fetching albums!');
            console.log(err);
        }
    };

    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        //  States
        const { albums } = this.state;

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Download Media')} backBtn={true} func={[this.getAlbums]} showChildSelector={true} />
                        <div className='grey-section'>
                            {albums !== null && albums.length > 0 ? (
                                <div className='d-flex flex-wrap flex-wrap'>
                                    {albums.map((v, i) => {
                                        let data = {
                                            allData: v,
                                            name: v.album_name,
                                            description: v.album_desc,
                                            // linkTo: '/subject/lessons/' + v.acs_name,
                                            // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                                            linkTo: '/download-media/album/' + v.album_id + '/' + v.album_name,
                                            cardImage: (v.album_picture) ? portalURL + this.props.authData.centerData.center_uuid + '/albums/' + v.album_picture : require('../../assets/images/noimage.png'),
                                        }
                                        return <GeneralCard data={data} key={i} />
                                    })}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        height: '120px',
                                        flexDirection: 'column',
                                    }}
                                    className='d-flex justify-content-center align-items-center'>
                                    {albums == null ? (
                                        <>
                                            <span className='mb-1'>
                                                <Spinner
                                                    animation='grow'
                                                    variant='dark'
                                                />
                                            </span>
                                            {t('Fetching albums...')}
                                        </>
                                    ) : (
                                        t('No albums available!')
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    languages: state.language.languages,
    defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(withParams(DownloadMedia));
