import { store } from "../redux/store";

/**
 * Translate
 * @param {string} fieldName Text that needs to be translated
 * @param {string} lang_column Language column in case of specific translation
 * @returns {string} Translate the given string with the default selected language.
 */
export function t(fieldName, lang_column) {
    // let getLangLabels = JSON.parse(sessionStorage.getItem('langLabels'));
    // let defaultLanguage = JSON.parse(sessionStorage.getItem('defaultLanguage'));

    let state = store.getState()
    let getLangLabels = state.language.langLabels
    let defaultLanguage = state.language.defaultLanguage

    let data = fieldName;
    if (defaultLanguage) {
        if (lang_column == undefined) {
            lang_column = defaultLanguage['lang_column'];
        }

        let translated = getLangLabels.find((v, i) => {
            return v.field_name == fieldName;
        });
        data = translated != undefined ? translated[lang_column] : fieldName;
    }
    return data;
}
