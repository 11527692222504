import { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Modal, ListGroup, Image } from 'react-bootstrap';
import { setSelectedChild } from '../../../redux/slices/selectedChildSlice';
import { t } from '../../../helpers/translation_helper';
import { Black, White } from '../../../helpers/brand_colors_helper';

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Child Selector Partial Component
 */
class ChildSelector extends Component {
  /**
   * @constructor
   * @param {any} props
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedChild: {},
      children: [],
      modal: false,
    };
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.setState({
      selectedChild:
        this.props.selectedChild ||
        this.props.authData.children[0] ||
        {},
      children: this.props.authData.children,
    });
  }

  /**
   * Handle Modal
   */
  handleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  /**
   * Children List
   * @returns {HTMLElement} Outputs the children list.
   */
  childrenList = () => {
    let list = [];
    if (this.state.children && this.state.children.length > 0) {
      list = this.state.children.map((item, key) => (
        <ListGroup.Item
          key={key}
          className='child-list-item'
          onClick={() =>
            this.handleChildChange(item.child_id, item.class_id)
          }>
          <div className='child-pic list-child-pic'>
            {item.picture ? (
              <Image
                src={
                  portalURL + this.props.authData.centerData.center_uuid +
                  '/children/' +
                  item.picture
                }
                thumbnail={true}
                fluid={true}
              />
            ) : (
              <FontAwesomeIcon icon={faUser} color='#ffffff' />
            )}
          </div>
          <div>
            <strong>{item.child_name}</strong>
            <br />
            <div style={{ fontWeight: 600, fontSize: 12, color: item.agegroup_color }}>{item.class_theme}</div>
          </div>
        </ListGroup.Item>
      ));
    }
    return list;
  };

  /**
   * Handle Child Change
   * @param {number} child_id ID of the child
   * @param {number} class_id ID of the class
   */
  handleChildChange = (child_id, class_id) => {
    const { children } = this.state;

    const findChild = children.find((child) => {
      return child.child_id == child_id && child.class_id == class_id;
    });

    this.props.setSelectedChild(findChild);

    this.setState(
      {
        selectedChild: findChild,
        modal: false,
      },
      () => {
        if (this.props.func?.length > 0) {
          this.props.func.map((func) => {
            return func();
          });
        }
      }
    );
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { lang_orientation: dir } = this.props.defaultLanguage;
    const { selectedChild, modal } = this.state;
    return (
      <>
        {(this.props.minimal)
          ? <div id='selected-child' style={{ padding: '0 10px 0 18px', cursor: 'pointer', background: White }}>
            <div className='d-flex justify-content-center align-items-center' style={{ fontSize: 16, fontWeight: 'bold', color: Black, opacity: 0.8 }} onClick={() => this.handleModal()}>
              <div className='me-3' style={{ lineHeight: '15px' }}>
                <div>{selectedChild.child_name}</div>
                <div style={{ fontWeight: 600, fontSize: 12, color: selectedChild.agegroup_color }}>{selectedChild.class_theme}</div>
              </div>
              <div><FontAwesomeIcon icon={faChevronDown} color={'#e64689'} /></div>
            </div>
          </div>
          :
          <div id='selected-child'>
            <div className='child-pic'>
              {selectedChild.picture ? (
                <Image
                  src={
                    portalURL + this.props.authData.centerData.center_uuid +
                    '/children/' +
                    selectedChild.picture
                  }
                  thumbnail={true}
                  fluid={true}
                />
              ) : (
                <FontAwesomeIcon icon={faUser} color='#ffffff' />
              )}
            </div>
            <div>
              <h5 className='child-name'>
                <span onClick={() => this.handleModal()} style={{ color: Black, opacity: 0.8 }}>
                  {dir == 0 && t('Hi')} {selectedChild.child_name}{' '}
                  {dir == 1 && t('Hi')}
                </span>
              </h5>
              <h6 className='class-name'>
                <span style={{ color: selectedChild.agegroup_color }} onClick={() => this.handleModal()}>
                  {selectedChild.class_theme}{' '}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color='#EE3A81'
                  />
                </span>
              </h6>
              {/* PASTORAL CARE POINTS */}
              <div className='d-flex flex-row'>
                <Image src={require('../../../assets/images/total-points.png')} style={{ width: 40, height: 40 }} />
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div style={{ color: Black, fontSize: 12, opacity: 0.8, fontWeight: 'bold' }}>{t('Pastoral Care')}</div>
                  <div style={{ color: Black, fontSize: 14, opacity: 0.8, fontWeight: 'bold' }}>{selectedChild.totalPoints}</div>
                </div>
              </div>
            </div>
          </div>
        }
        <Modal show={modal} size='sm' onHide={this.handleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Select Child')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-0 pb-2'>
            <ListGroup variant='flush'>
              {this.childrenList()}
            </ListGroup>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  defaultLanguage: state.language.defaultLanguage,
});

const mapDispatchToProps = () => ({
  setSelectedChild,
});

export default connect(mapStateToProps, mapDispatchToProps())(ChildSelector);
