import { toast } from 'react-toastify';
import { t } from './translation_helper';
/**
 * Set Value for Text Field
 * @param {String} field Name of the field
 * @param {String} data Data to set for the field
 * @param {Object} thisObj Object that field exist in
 */
export const setTxtField = (field, data, thisObj) => {
    thisObj.setState({
        [field]: data,
    });
};

/**
 * Set Value for Switch Field
 * @param {String} field
 * @param {Integer} data
 * @param Object} thisObj
 */
export const setSwitchField = (field, data, thisObj) => {
    thisObj.setState({
        [field]: data == 1 ? 0 : 1,
    });
};

/**
 * Handle Form Errors
 * @param {Object} errorData
 */
export const handleFormErrors = (errorData) => {
    const errors = [];

    for (const key in errorData) {
        errors.push(errorData[key]);
    }

    const Msg = ({ closeToast, toastProps }) => (
        <div>
            {t('Error!')}
            <ul style={{ paddingLeft: 15 }}>
                {errors.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </div>
    );
    toast.error(<Msg />);
};
