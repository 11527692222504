import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner, Image } from 'react-bootstrap';
import { t } from '../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faEdit, faHomeUser, faListOl, faPencil, faPlus, faSave, faSearch, faTrashCan, faXmark, faEllipsisV, faTrashAlt, faHeart } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select'
import { Chance } from 'chance';
import { Link, NavLink } from 'react-router-dom';
import { getExtension } from '../helpers/general_helpers';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { Black, White } from './brand_colors_helper';
const chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;
class GeneralCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actionDDL: false,
            cardWidth: '25'
        }
    }

    componentDidMount() {
        this.resize()
        window.addEventListener('resize', this.resize)
    }
    resize = () => {
        //  1536 739
        //  1920 955
        let WIDTH = window.innerWidth
        let HEIGHT = window.innerHeight
        console.log('WIDTH', WIDTH);
        if (WIDTH > 1600) {
            if (this.state.cardWidth != '20') {
                this.setState({ cardWidth: '20' })
                console.log('RESIZE CHANGED', 20)
            }
        } else {
            if (this.state.cardWidth != '25') {
                this.setState({ cardWidth: '25' })
                console.log('RESIZE CHANGED', 25)
            }
        }
    }


    render() {
        let { actionDDL } = this.state
        // let linkTo = ''
        // let linkToStates = {}

        // let cardImage = require('../assets/images/noimage.png')
        // let name = 'Name Name Name'
        // let code = 'CODE'
        // let text1 = 'Name2'
        // let text2 = 'Name3'
        // let description = 'Create consistent cross-browser and cross-device checkboxes and radios with our completely rewritten checks component.'
        // let status = 1
        // let actions = [
        //     { name: 'Edit', iconName: faEdit, iconColor: Black, onClick: () => { } },
        //     { name: 'Delete', iconName: faTrashAlt, iconColor: Black, onClick: () => { } }
        // ]
        // let extraTexts = [
        //     { type: 'url', name: 'URL', value: 'www.google.com', iconName: null, image: require('../assets/images/add-button-small.png'), iconColor: Black, onClick: () => { } },
        //     { type: 'text', name: 'TOPIC', value: 'Some topic Name', icon: null, image: require('../assets/images/hyperlink.png'), iconColor: Black, onClick: () => { } }
        // ]

        if (!this.props.data) {
            return null
        }
        let { linkTo, linkToStates, cardImage, name, code, text1, text2, actions, description, extraTexts, status, onClick } = this.props.data



        return (<div className='d-flex flex-column justify-content-between' key={this.props.key} style={{ width: 'calc(' + this.state.cardWidth + '% - 2rem)', backgroundColor: White, borderRadius: '1rem', margin: '1rem', position: 'relative', boxShadow: '-1px 2px 16px 2px rgba(0,0,0,0.1)', padding: 0 }}>
            <div style={{ padding: '1rem', }}>
                <Link to={linkTo} state={linkToStates} onClick={onClick}>
                    <Image src={cardImage} style={{ objectFit: 'cover', width: '100%', height: '9.5rem', backgroundColor: White, borderRadius: '1rem' }} />
                </Link>
                <div className='d-flex justify-content-between mb-2'>
                    <div>
                        <div>
                            <strong style={{ fontSize: '1rem' }}>{name}</strong>
                            {code && <span className='mx-1 text-muted' style={{ fontSize: '0.9rem' }}>{code}</span>}
                        </div>
                        {(text1 || text2) &&
                            <div>
                                {text1 && <span style={{ fontSize: '1rem', opacity: 0.9 }}>{text1}</span>}
                                {text2 && <span className='mx-1 text-muted' style={{ fontSize: '0.9rem' }}>{text2}</span>}
                            </div>
                        }
                    </div>
                    {(status == 1 || status == 0)
                        ? <div>
                            <Badge className='me-2' bg={status == 1 ? 'success' : 'danger'}> {status == 1 ? t('Active') : t('Inactive')} </Badge>
                        </div>
                        : (status) && <div>
                            <Badge className='me-2' bg={status.bg}> {status.name} </Badge>
                        </div>
                    }
                </div>
                <div>
                    {extraTexts && extraTexts.map((v, i) => {
                        if (v.type == 'url') {
                            return <p key={i} className="mb-0" style={{ fontSize: '0.8rem' }}>
                                <a href={v.value} target='_blank' >
                                    {v.image && <Image src={v.image} style={{ width: '1rem', height: '1rem' }} />}
                                    {v.icon && <FontAwesomeIcon icon={v.icon} color={v.iconColor} style={{ fontSize: '1rem', }} />}
                                    <span className='mx-1'>{v.name}</span>
                                </a>
                            </p>
                        } else {
                            return <p key={i} title={v.name} className="mb-0" style={{ fontSize: '0.8rem' }}>
                                {v.image && <Image src={v.image} style={{ width: '1rem', height: '1rem' }} />}
                                {v.icon && <FontAwesomeIcon icon={v.icon} color={v.iconColor} style={{ fontSize: '1rem', }} />}
                                <span className='mx-1'>{v.value}</span>
                            </p>
                        }
                    })}

                    {description && <span title={description} style={{ fontSize: '0.8rem', color: Black, opacity: 0.8 }}>{(description.length > 60) ? description.slice(0, 60) + '...' : description}</span>}

                    {/* {actions && actions.length > 0 &&
                        <button style={{ backgroundColor: 'transparent', border: 'none', position: 'absolute', right: '0.5rem', bottom: '0.5rem' }} onClick={() => this.setState({ actionDDL: !actionDDL })}>
                            <FontAwesomeIcon icon={faEllipsisV} color={(actionDDL) ? brand_colors[this.props.defaultTheme.theme_id].color18 : Black} style={{ fontSize: '1rem', }}
                            />
                        </button>
                    }
                    {actions && actions.length > 0 &&
                        <ul className={actionDDL ? 'subject-action-dd dropdown-menu show' : 'subject-action-dd dropdown-menu'}>
                            {actions.map((v, i) => {
                                return <li key={i}>
                                    <button onClick={() => {
                                        this.setState({ actionDDL: !actionDDL }, () => {
                                            v.onClick()
                                        })
                                    }} className='d-flex align-items-center'>
                                        {v.image && <Image src={v.image} style={{ width: '1rem', height: '1rem' }} />}
                                        {v.iconName && <FontAwesomeIcon icon={v.iconName} color={v.iconColor} style={{ fontSize: '1rem' }} />}
                                        <span className='mx-1'>{t(v.name)}</span>
                                    </button>
                                </li>
                            })}
                        </ul>
                    } */}
                </div>
            </div>
            {actions && actions.length > 0 &&
                <div style={{ borderBottomLeftRadius: '1rem', borderBottomLeftRadius: '1rem', borderTop: 'solid 1px #e0e0e0' }}>
                    <div className='d-flex justify-content-evenly align-items-center flex-row flex-wrap'>
                        {actions.map((v, i) => {
                            return (<div key={i} className='d-flex flex-column align-items-center justify-content-center cursor-pointer' style={{ padding: '0.5rem 1rem', }} onClick={() => v.onClick()} >
                                {v.image && <Image title={t(v.name)} src={v.image} style={{ width: '1rem', height: '1rem' }} />}
                                {v.iconName && <FontAwesomeIcon title={t(v.name)} icon={v.iconName} color={v.iconColor} style={{ fontSize: '1rem' }} />}
                            </div>)
                        })}
                    </div>
                </div>
            }
        </div>);
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps())(GeneralCard);
