/**
 * Authorization Slice
 */
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authenticated: false,
        authData: {},
    },
    reducers: {
        setAuthentication: (state, action) => {
            state.authData = action.payload;
            state.authenticated = true;
        },
        unsetAuthentication: (state) => {
            state.authData = {};
            state.authenticated = false;
        },
    },
});

export const { setAuthentication, unsetAuthentication } = authSlice.actions;
export default authSlice.reducer;
