import React, { Component } from 'react';
import { Container, Card, Table, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';

/**
 * Profile Component
 */
class Profile extends Component {
    /**
     * Render Component
     * @returns {HTMLElement}
     */
    render() {
        const {
            child_name,
            birth_date,
            class_theme,
            email1,
            phone1,
            emergency_contact_name,
            emergency_phone1,
        } = this.props.selectedChild;

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Profile')} backBtn={true} />
                        <div className='grey-section'>
                            <Card
                                className='border-0'
                                style={{ borderRadius: '20px' }}>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Table className='mb-5'>
                                                <tbody>
                                                    <tr>
                                                        <th width='200'>
                                                            {t('Name')}
                                                        </th>
                                                        <td>{child_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            {t('Date of Birth')}
                                                        </th>
                                                        <td>{birth_date}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{t('Class')}</th>
                                                        <td>{class_theme}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{t('Email')}</th>
                                                        <td>{email1}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>{t('Phone')}</th>
                                                        <td>{phone1}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th width='200'>
                                                            {t(
                                                                'Emergency Contact'
                                                            )}
                                                        </th>
                                                        <td>
                                                            {
                                                                emergency_contact_name
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            {t(
                                                                'Emergency Contact No.'
                                                            )}
                                                        </th>
                                                        <td>
                                                            {emergency_phone1}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedChild: state.selectedChild.data,
    defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(Profile);
