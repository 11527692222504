import React, { Component } from 'react';
import { Container, Card, Col, Row, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { withParams } from '../../helpers/params_helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, Link } from 'react-router-dom';
import {
  faCalendarAlt,
  faClock,
  faExternalLink,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import LessonCard from './LessonCard';

import Paginate from '../_partials/Paginate/_Paginate';



import './subject.css';
import GeneralCard from '../../helpers/GeneralCard';
import { VividMalachite } from '../../helpers/brand_colors_helper';
import { changeColorOpacity } from '../../helpers/general_helpers';

// API URL
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Subject Component
 */
class Subject extends Component {
  // State
  state = {
    todaysLessons: null,
    lessons: null,
    limit: 12,
    totalRecords: 0,
    // Paginate
    examsData: {
      data: [],
      limit: 30,
      offset: 0,
      totalRecords: 0,
      lastPage: 0,
    },
    homeworkData: {
      data: [],
      limit: 30,
      offset: 0,
      totalRecords: 0,
      lastPage: 0,
    }
  };

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getExams();
    this.getHomeworks();
    this.getLessons();
    // this.getTodaysLessons();
  }

  /**************************************
   * Get Exams
   */
  getExams = async (limit = this.state.examsData.limit, offset = 0) => {
    const { authData, params } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { selectedChild } = this.props;
    let examsData = this.state.examsData;
    let request_params = {
      center_id,
      class_id: selectedChild.class_id,
      child_id: selectedChild.child_id,
      acs_id: params.acs_id,
      is_homework: 0,
      limit: limit,
      offset: offset,
      auth_key: auth_key,
    };

    try {
      const res = await axios.get(apiURL + 'exams/get-exams-new', {
        params: request_params,
      });
      this.setState({
        examsData: { ...examsData, data: res.data.data, totalRecords: parseInt(res.data.total_count_filtered), lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / limit), },
      });
    } catch (err) {
      toast.error('Something went wrong while fetching exams!');
      console.log(err);
    }
  };
  getHomeworks = async (limit = this.state.homeworkData.limit, offset = 0) => {
    const { authData, params } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { selectedChild } = this.props;
    let homeworkData = this.state.homeworkData;
    let request_params = {
      center_id,
      class_id: selectedChild.class_id,
      child_id: selectedChild.child_id,
      acs_id: params.acs_id,
      is_homework: 1,
      limit: limit,
      offset: offset,
      auth_key: auth_key,
    };

    try {
      const res = await axios.get(apiURL + 'exams/get-exams-new', {
        params: request_params,
      });
      this.setState({
        homeworkData: {
          ...homeworkData, data: res.data.data, totalRecords: parseInt(res.data.total_count_filtered), lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / limit),
        },
      });
    } catch (err) {
      toast.error('Something went wrong while fetching exams!');
      console.log(err);
    }
  };

  /**
   * Render Exam Item
   * @returns {HTMLElement}
   */
  renderExams = () => {
    const { examsData } = this.state;
    return examsData.data.map((item, index) => {
      return (
        <Col md={2} className='mb-3' key={index}>
          <Card className='text-center' style={{ height: '100%' }}>
            <Card.Body>
              <Card.Title className='mb-2'>
                {item.exams_name}
              </Card.Title>
              <Card.Subtitle className='mb-2 text-muted'>
                {item.acs_name}
              </Card.Subtitle>
              <Card.Text>{item.exams_notes}</Card.Text>
              <Card.Text
                className='mb-1'
                style={{ fontSize: '13px' }}>
                <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                {moment(item.exams_date).format('DD-MM-YYYY')}
              </Card.Text>
              <Card.Text style={{ fontSize: '13px' }}>
                <FontAwesomeIcon icon={faClock} />{' '}
                {item.open_time} - {item.close_time}
              </Card.Text>
              {item.is_exampaper != 1 &&
                <Link to={'/attempt-exam/' + item.exams_id + '/' + (item.exam_retake_id ? item.exam_retake_id : 0) + '/' + item.is_homework} className='btn btn-primary'>
                  <FontAwesomeIcon icon={faFileAlt} />{' '}
                  <span className='ms-1'>
                    {t('Attempt')}
                  </span>
                </Link>}
            </Card.Body>
          </Card>
        </Col >
      );
    });
  };
  renderHomework = () => {
    const { homeworkData } = this.state;
    return homeworkData.data.map((item, index) => {
      return (
        <Col md={2} className='mb-3' key={index}>
          <Card className='text-center' style={{ height: '100%' }}>
            <Card.Body>
              <Card.Title className='mb-2'>
                {item.exams_name}
              </Card.Title>
              <Card.Subtitle className='mb-2 text-muted'>
                {item.acs_name}
              </Card.Subtitle>
              <Card.Text>{item.exams_notes}</Card.Text>
              <Card.Text
                className='mb-1'
                style={{ fontSize: '13px' }}>
                <FontAwesomeIcon icon={faCalendarAlt} />{' '}
                {moment(item.exams_date).format('DD-MM-YYYY')}
              </Card.Text>
              <Card.Text style={{ fontSize: '13px' }}>
                <FontAwesomeIcon icon={faClock} />{' '}
                {item.open_time} - {item.close_time}
              </Card.Text>
              {item.is_exampaper != 1 && <Link
                to={
                  '/attempt-exam/' +
                  item.exams_id +
                  '/' +
                  (item.exam_retake_id
                    ? item.exam_retake_id
                    : 0)
                }
                className='btn btn-primary'>
                <FontAwesomeIcon icon={faFileAlt} />{' '}

                <span className='ms-1'>
                  {t('Attempt')}
                </span>
              </Link>}
            </Card.Body>
          </Card>
        </Col >
      );
    });
  };

  /**************************************************
   * Get Lessons
   */
  getLessons = async (limit = this.state.limit, offset = 0) => {
    const { authData, params } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { selectedChild } = this.props;
    let request_params = {
      center_id,
      acs_id: params.acs_id,
      limit,
      offset,
      auth_key: auth_key,
      class_id: this.props.selectedChild.class_id,
      order: 'timetable_lessons.activation_date',
      dir: 'DESC'
    };

    try {
      const res = await axios.get(
        apiURL + 'academic-subject-lessons/get',
        {
          params: request_params,
        }
      );

      const { limit } = this.state;

      this.setState({
        lessons: res.data.data,
        totalRecords: parseInt(res.data.total_count),
        lastPage: Math.ceil(parseInt(res.data.total_count) / limit),
      });
    } catch (err) {
      toast.error('Something went wrong while fetching lessons!');
      console.log(err);
    }
  };

  /**
   * Get Today's Lessons
   */
  getTodaysLessons = async () => {
    const { authData, params } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { selectedChild } = this.props;
    let request_params = {
      center_id,
      class_id: selectedChild.class_id,
      class_theme: selectedChild.class_theme,
      material_id: params.material_id,
      auth_key: auth_key,
    };

    try {
      const req = await axios.get(
        apiURL + 'dashboard/get-active-lesson-single',
        {
          params: request_params,
        }
      );

      this.setState({
        todaysLessons: req.data.data,
      });
    } catch (err) {
      toast.error("Something went wrong while fetching today's lessons!");
      console.log(err);
    }
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    console.log('this.state', this.state);
    const { examsData, lessons, todaysLessons, limit, totalRecords, homeworkData } = this.state;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t(this.props.params.acs_name)} backBtn={true} />
            <div className='grey-section'>
              {/* <Card
                                className='border-0 mb-4'
                                style={{
                                    borderTopLeftRadius: 20,
                                    borderTopRightRadius: 20,
                                }}>
                                <Card.Body>
                                    <Card.Title className='mb-3'>
                                        {t("Today's Lessons")}
                                    </Card.Title>
                                    {todaysLessons !== null &&
                                    todaysLessons.length > 0 ? (
                                        <Row>
                                            {this.renderLessons(todaysLessons)}
                                        </Row>
                                    ) : (
                                        <div
                                            style={{
                                                height: '120px',
                                                flexDirection: 'column',
                                            }}
                                            className='d-flex justify-content-center align-items-center'>
                                            {todaysLessons == null ? (
                                                <>
                                                    <span className='mb-1'>
                                                        <Spinner
                                                            animation='grow'
                                                            variant='dark'
                                                        />
                                                    </span>
                                                    {t(
                                                        "Fetching today's lessons..."
                                                    )}
                                                </>
                                            ) : (
                                                t(
                                                    'No lessons available for today!'
                                                )
                                            )}
                                        </div>
                                    )}
                                </Card.Body>
                            </Card> */}
              <h5 className='mb-0'>{t('All Lessons')}</h5>
              {lessons !== null && lessons.length > 0 ? (
                <>
                  <Row>
                    {lessons.map((v, i) => {

                      let data = {
                        allData: v,
                        name: v.asl_name,
                        code: v.asl_code,
                        // linkTo: '/subject/lessons/' + v.acs_name,
                        // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                        linkTo: '/lesson-details/' + v.asl_id,
                        linkToStates: { lessonData: v },
                        cardImage: (v.asl_attach) ? portalURL + this.props.authData.centerData.center_uuid + '/academic_subject_lessons/' + v.asl_attach : require('../../assets/images/noimage.png'),
                        extraTexts: [
                          { type: 'activation', name: 'Activation', value: v.activation_date, icon: faCalendarAlt, image: null, iconColor: changeColorOpacity(VividMalachite, 0.5), onClick: () => { } }
                        ]
                      }
                      return <GeneralCard data={data} key={i} />
                      // <LessonCard
                      //   key={index}
                      //   data={item}
                      //   handleAddEditFormOpen={
                      //     this.handleAddEditFormOpen
                      //   }
                      //   handleDelete={this.delAlert}
                      // />
                    })}
                  </Row>
                </>
              ) : (
                <div
                  style={{
                    height: '120px',
                    flexDirection: 'column',
                  }}
                  className='d-flex justify-content-center align-items-center'>
                  {lessons == null ? (
                    <>
                      <span className='mb-1'>
                        <Spinner
                          animation='grow'
                          variant='dark'
                        />
                      </span>
                      {t('Fetching lessons...')}
                    </>
                  ) : (
                    t('No lessons available!')
                  )}
                </div>
              )}
              <Paginate limit={limit} totalRecords={totalRecords} pageChange={this.getLessons} />
              <Card
                className='border-0 mt-4'
                style={{
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}>
                <Card.Body>
                  <Card.Title className='mb-3'>
                    {t('Homeworks')}
                  </Card.Title>
                  {homeworkData.data.length > 0 ? (
                    <Row>{this.renderHomework()}</Row>
                  ) : (
                    <div style={{ height: '120px', flexDirection: 'column', }}
                      className='d-flex justify-content-center align-items-center'>
                      {t('No homework available!')}
                    </div>
                  )}
                  <Paginate limit={homeworkData.limit} totalRecords={homeworkData.totalRecords} pageChange={this.getHomeworks} />
                </Card.Body>
              </Card>
              <Card
                className='border-0 mt-4'
                style={{
                  borderBottomLeftRadius: 20,
                  borderBottomRightRadius: 20,
                }}>
                <Card.Body>
                  <Card.Title className='mb-3'>
                    {t('Exams')}
                  </Card.Title>
                  {examsData.data.length > 0 ? (
                    <Row>{this.renderExams()}</Row>
                  ) : (
                    <div
                      style={{
                        height: '120px',
                        flexDirection: 'column',
                      }}
                      className='d-flex justify-content-center align-items-center'>
                      {t('No exams available!')}
                    </div>
                  )}
                  <Paginate limit={examsData.limit} totalRecords={examsData.totalRecords} pageChange={this.getExams} />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedChild: state.selectedChild.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(withParams(Subject));
